import React from 'react';
import { Waypoint } from 'react-waypoint';

import DownloadStripe from './DownloadStripe.jsx';

let counter = 0;

class Bottom extends React.Component {
  
  constructor() {
    super();
    
    this.handlePositionChange = this.handlePositionChange.bind(this);
  }
  
  googleColor(i) {
    switch (i%4) {
      case 0: return 'blue';
      case 1: return 'red';
      case 2: return 'yellow';
      case 3: return 'green';
    }
  }
  
  get googleSection() {
    return (
      <span className="google">
        {"Google Calendar".split('').map((el, i) => (
          <span key={i} className={this.googleColor(i)}>{el}</span>
        ))}.
      </span>
    );
  }
  
  get identitySection() {
    return (
      <div>
        <div>and a movie this weekend,</div>
        <div>or soccer in the park after 3,</div>
        <div>or the bar on Thursday night,</div>
        <div>or lunch after class next week.</div>
      </div>
    );
  }
  
  get identitySectionParagraphs() {
    return (
      <span>
        <span className="block">and a movie this weekend,</span>
        <span className="block">or soccer in the park after 3,</span>
        <span className="block">or the bar on Thursday night,</span>
        <span className="block">or lunch after class next week.</span>
      </span>
    );
  }
  
  handlePositionChange(e) {
    counter ++;
    if (e.currentPosition !== 'below'){
      if (counter === 1) this.props.handleInitialStaticPhone();
      this.props.handleFixedPhone('off');
    } else if (this.props.fixedPhone === false) {
      this.props.handleFixedPhone('on');
    }
  }
  
  render() {
    let content;
    if (this.props.width <= 700) {
      content = (
        <div className="identity identity-paragraphs">
          <p>
            We are not&nbsp; 
            {this.googleSection}
          </p>
          <p>We are not&nbsp;
            <span className="facebook">Facebook Events</span>.
          </p>
          <p>
            <span className="block">We are you,</span>
            <span className="block"> a group of friends,</span>
            {this.identitySectionParagraphs}
          </p>
          <p>We are <span className="color-tally">Tally</span>.</p>
        </div>
      );
    } else {
      content = (
        <table className="identity-table identity">
          <tbody>
            <tr>
              <td className="cell-right">We are not&nbsp;</td>
              <td>{this.googleSection}</td>
            </tr>
            <tr>
              <td className="cell-right">We are not&nbsp;</td>
              <td><span className="facebook">Facebook Events</span>.</td>
            </tr>
            <tr>
              <td className="cell-right">We are&nbsp;</td>
              <td>
                <div>you,</div>
                <div>a group of friends,</div>
                {this.identitySection}
              </td>
            </tr>
            <tr>
              <td className="cell-right">We are&nbsp;</td>
              <td><span className="color-tally">Tally</span>.</td>
            </tr>
          </tbody>
        </table>
      );
    }
    return (
      <div id="bottom">
        <Waypoint onPositionChange={this.handlePositionChange}/>
        <div className="sections-container full-window-height">
          <div className="section">
            {content}
            <DownloadStripe/>
          </div>
        </div>
      </div>
    );
  }
}

export default Bottom;
