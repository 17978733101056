import React from 'react';

class Link extends React.Component {

  get browsers() {
    return ['firefox', 'safari', 'chrome'];
  }

  get browserIcons() {
    return this.browsers.map((browser, i) => (
      // <i key={i} className={`devicon-${b}-plain ${this.props.link.anchor ? 'colored' : ''}`}></i>
      // <i  className={`devicon-${b} colored`}/>
      <img key={i} src={`https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${browser}/${browser}-original.svg`} />
    ));
  }

  get style() {
    if (this.props.link.text === 'gettally.io') return {paddingLeft: '30px'};
    else return {};
  }

  render() {
    let iconHolder, p;
    const { icon, text, desc } = this.props.link;
    if (!icon && !text && !desc) {
      return (
        <div className="link-table flex-wrap">
          <p style={{textAlign: 'center', padding: '0 3%'}}>
            all of my work at {this.props.id} was performed on internal tools.
            <br/>
            no public-facing website available.
          </p>
        </div>
      );
    }
    if (icon === 'special') {
      iconHolder = (
        <span className={`link-image ${desc ? 'link-image-padding': ''}`}>
          <td className="logo-icon multi-logo-icons">
            {this.browserIcons}
          </td>
        </span>
      );
    } else {
      iconHolder = (
        <span className="link-image">
          <td className="logo-icon">
            <i className={`devicon-${icon} colored`}/>
          </td>
        </span>
      );
    }
    if (desc) p = <p>{desc}</p>;
    return (
      <table className="link-table flex-wrap">
        <tbody>
          <a href={this.props.link.anchor} target="_blank">
            <tr style={icon === 'special' ? {} : {color: '#e1e1e1', cursor: 'not-allowed'}}>
              {iconHolder}
              <td className="link-text bold-500">
                <h2 style={this.style}>{text}</h2>
                {p}
              </td>
            </tr>
          </a>
        </tbody>
      </table>
    );
  }
}

export default Link;
