import React from 'react';
import c3 from 'c3';

export default class StackChart extends React.Component {
	
    componentDidMount() {
        this.updateData();
		setTimeout(() => {
			this.updateChartStyling();
		}, 500);
    }
	
	updateChartStyling() {
		function $$(selector, context) {
			context = context || document;
			var elements = context.querySelectorAll(selector);
			return Array.prototype.slice.call(elements);
		}
		
		const list = $$('#enim-bibendum .c3-axis.c3-axis-x g.tick');
		list.forEach((li) => {
            if (li.lastChild.style.display === "none") {
				li.firstChild.style.display = "none";
            }
		});
	}
    
    updateData() {
        c3.generate({
            size: {
                height: this.props.height
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: { format: '%b %Y', outer: false }
                },
                y: {
                    max: 600,
                    padding: {top: 0},
                    label: { text: 'Enim Bibendum',  position: 'outer-middle'},
                    tick: { outer: false, count: 7 }
                }
            },
            bindto: '#' + this.props.id,
            data: {
                order: null,
                x: "date",
                json: this.props.data,
                color: function (color, d) {
					return ['#39b3e4','#fb7c11','#954ce9'][(d.id ? d.id.slice(-1) : d.slice(-1)) % 3];
                },
                keys: {
                    x: "date",
                    value: Array(3).fill("Time Period").map((t, i) => t + " " + (i + 1))
                },
                type: 'bar',
                groups: [Array(3).fill("Time Period").map((t, i) => t + " " + (i + 1))],
            },
            grid: {
                focus: { show: false },
                y: { show: true }
            },
            tooltip: {
                show: false
            },
            padding: {top: 20, bottom: 40, right: 34, left: 75},
            legend: {
                show: true,
                position: 'inset',
                inset: {
                    anchor: 'top-left',
                    x: 20,
                    y: 20,
                    step: 3
                }
            }
        });
    }

    render() {
        return (
            <div id={this.props.id}/>
        );
    }
}