import React from 'react';
import c3 from 'c3';

export default class SplineChart extends React.Component {
    
    constructor() {
        super();
        
        this.updateData = this.updateData.bind(this);
    }
    
    componentDidMount() {
        this.updateData();
    }
 
    updateData() {
        let json, columns, legend, color, type;
        const {data, height, id, dateFormat, label} = this.props;
        if (this.props.subtype === "multiline") {
            type = 'spline';
            columns = data;
            legend = {
                show: true,
                  position: 'inset',
                  inset: {
                    anchor: 'top-right',
                    x: 10,
                    y: 15,
                    step: 3
                  }
              };
              color = {pattern: ['#4000C0', '#39b3e4', '#fb7c11', '#31da7d', '#ae60ca', '#d23b2b', '#f9d70b', '#ea4c87', '#171ad3']}
        } else {
            type = 'line';
            json = data;
            legend = {show: false};
        }
        c3.generate({
          data: {
            type,
            json,
            columns,
            x: "date",
            keys: {
              x: 'date',
              value: ['value'],
            }
          },
          tooltip: {
            show: false
          },
          size: {
            height,
          },
          padding: {left: 75, right: 52, bottom: 40},
          axis: {
            x: {
              type: 'timeseries',
              tick: {
                format: dateFormat,
                outer: false,
                count: 4
              }
            },
            y: {
              tick: { outer: false },
              padding: {bottom: 0, right: 1000},
              min: 0,
              label: { text: label,  position: 'outer-middle'}
            }
          },
          bindto: '#' + id,
          color,
          grid: {
            focus:{show: false },
            y: { show: true }
          },
          legend,
          point: {show: false},
        });
    }
  
  render() {
    return (
      <div id={this.props.id} className="spline-chart"/>
    );
  }
}