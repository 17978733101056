import WordCloud from 'react-d3-cloud';
import React from 'react';

export default class Cloud extends React.Component {

  render() {
    const GENE_DATA = this.props.data;

    var result = [];

    const GENE_DATA_KEYS = Object.keys(GENE_DATA);

    for (let key in GENE_DATA_KEYS) {
      result.push({text: GENE_DATA_KEYS[key], value: GENE_DATA[GENE_DATA_KEYS[key]]});
    }

    const fontSizeMapper = word => Math.log2(word.value) * 5;
    const rotate = word => word.value % 90 * (word.value % 2 ? 1 : -1) ;

    return (
      <div id={this.props.id} className="cloud-card-content">
        <WordCloud
          data={result}
          fontSizeMapper={fontSizeMapper}
          rotate={rotate}
          width={440}
          height={380}
          font="Roboto"
        />
      </div>
    );
  }
}
