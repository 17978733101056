import React from 'react';

class HoverBox extends React.Component {
  render() {
    return (
      <div className={`hover-box ${this.props.class}`}>
        <h3>{this.props.item.name}</h3>
        <div>{this.props.item.desc}</div>
      </div>
    );
  }
}

export default HoverBox;