import React, { Component } from 'react';

import './kong/kong.scss';
import './css/app.scss';

import Nav from './layout/Nav.jsx';
import SubNav from './layout/SubNav.jsx';
import MarqueeContainer from './layout/MarqueeContainer.jsx';
import KongPage from './kong/KongPage.jsx';

class DraftKings extends Component {

  constructor() {
    super();
    this.state = {
      tab: 'featured'
    };

    this.setTabState = this.setTabState.bind(this);
  }

  get tabs() {
    return ['featured', 'nba', 'mlb', 'golf', 'nhl', 'nas', 'mma', 'soc', 'lol'];
  }

  setTabState(tab) {
    this.setState({tab});
  }

  render() {
    return (
      <div id="draftkings-app">
        <div>
          <header>
            <Nav />
            <SubNav tabs={this.tabs} tabState={this.state.tab} setTabState={this.setTabState}/>
          </header>
          <div className="page-content">
            <MarqueeContainer/>
            <KongPage/>
          </div>
        </div>
      </div>
    );
  }
}

export default DraftKings;

