import React from 'react';

import Skills from './Skills.jsx';
import Tabs from './../tabs/Tabs.jsx';

// import f7 from './../../images/logos/logo-f7.png';
// import sketch from './../../images/logos/Sketch_logo_frame.png';
import chartjs from './../../images/logos/chartjs-logo.svg';
import cypress from './../../images/logos/cypress-logo.png';
import webflow from '../../images/logos/webflow-logo.png';
import contentful from '../../images/logos/contentful-logo.png';
import testing from './../../images/logos/testing-library-logo.png';
import shopify from './../../images/logos/shopify-logo.png';
import styledComponents from './../../images/logos/styled-components-logo.png';
// import redux from './../../images/logos/reduxLogo.png';
// import jest from './../../images/logos/jest-logo.png';

const SKILLS = [
  // {
  //   name: 'angular',
  //   icon: 'angularjs-plain',
  //   desc: 'js library',
  //   type: 'js',
  // },
  // {
  //   name: 'framework7',
  //   photo: f7,
  //   desc: 'html framework',
  //   type: 'html',
  // },
  {
    name: 'sanity',
    icon: 'sanity-plain',
    type: 'other',
    desc: 'headless cms',
  },
  {
    name: 'git',
    icon: 'git-plain',
    type: 'other',
    desc: 'version control system',
  },
  {
    name: 'html',
    icon: 'html5-plain',
    type: 'html',
  },
  {
    name: 'figma',
    photo: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg',
    desc: 'vector graphics software',
    type: 'styling',
  },
  {
    name: 'contentful',
    photo: contentful,
    type: 'other',
    desc: 'headless cms',
  },
  {
    name: 'chart.js',
    photo: chartjs,
    type: 'js',
    desc: 'data visualization',
  },
  // { name: 'gulp',
  //   icon: 'gulp-plain',
  //   desc: 'js task runner',
  //   type: 'other',
  // },
  // { name: 'meteor',
  //   icon: 'meteor-plain',
  //   desc: 'js framework',
  //   type: 'js',
  //   index: 3
  // },
  {
    name: 'd3',
    icon: 'd3js-plain',
    desc: 'data visualization',
    type: 'js',
  },
  // { name: 'grunt',
  //   icon: 'grunt-plain',
  //   desc: 'js task runner',
  //   type: 'other',
  // },
  {
    name: 'adobe illustrator',
    icon: 'illustrator-plain',
    desc: 'vector graphics software',
    type: 'styling',
  },
  {
    name: 'sketch',
    photo: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sketch/sketch-original.svg',
    type: 'styling',
    desc: 'vector graphics software',
  },
  {
    name: 'shopify',
    photo: shopify,
    type: 'html',
    desc: 'website builder',
  },
  {
    name: 'javascript',
    icon: 'javascript-plain',
    type: 'js',
  },
  // {
  //   name: 'less',
  //   icon: 'less-plain-wordmark',
  //   desc: 'css preprocessor',
  //   type: 'styling',
  // },
  {
    name: 'vue',
    icon: 'vuejs-plain',
    desc: 'js library',
    type: 'js',
  },
  // {
  //   name: 'jquery',
  //   icon: 'jquery-plain',
  //   desc: 'js library',
  //   type: 'js',
  // },
  {
    name: 'typescript',
    icon: 'typescript-plain',
    desc: 'typed js superset',
    type: 'js',
  },
  {
    name: 'material ui',
    photo: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/materialui/materialui-original.svg',
    desc: 'front-end framework',
    type: 'html',
  },
  {
    name: 'css',
    icon: 'css3-plain',
    type: 'styling',
  },
  {
    name: 'tailwind.css',
    icon: 'tailwindcss-original',
    desc: 'css framework',
    type: 'styling',
  },
  {
    name: 'react',
    icon: 'react-original',
    desc: 'js library',
    type: 'js',
  },
  {
    name: 'webflow',
    icon: 'webflow-original',
    desc: 'website builder & cms',
    type: 'html',
  },
  // { name: 'adobe photoshop',
  //   icon: 'photoshop-plain',
  //   desc: 'image editing software',
  //   type: 'styling',
  // },
  // {
  //   name: 'redux',
  //   icon: 'redux-plain',
  //   desc: 'js library',
  //   type: 'js',
  // },
  // {
  //   name: 'jasmine',
  //   icon: 'jasmine-plain',
  //   desc: 'js testing library',
  //   type: 'js',
  // },
  // {
  //   name: 'jest',
  //   icon: 'jest-plain',
  //   desc: 'js testing library',
  //   type: 'js',
  // },
  {
    name: 'graphql',
    icon: 'graphql-plain',
    desc: 'api data query',
    type: 'other',
  },
  // {
  //   name: 'sass & scss',
  //   icon: 'sass-original',
  //   desc: 'css preprocessor',
  //   type: 'styling',
  // },
  {
    name: 'storybook',
    icon: 'storybook-plain-wordmark',
    desc: 'ui component building tool',
    type: 'styling',
  },
  // {
  //   name: 'testing-library',
  //   photo: testing,
  //   desc: 'js testing library',
  //   type: 'js',
  // },
  // {
  //   name: 'mocha',
  //   icon: 'mocha-plain',
  //   desc: 'js testing library',
  //   type: 'js',
  // },
  {
    name: 'styled components',
    photo: styledComponents,
    desc: 'js styling library',
    type: 'styling',
  },
  {
    name: 'vercel',
    icon: 'vercel-original',
    desc: 'css preprocessor',
    type: 'other',
  },
  {
    name: 'next.js',
    icon: 'nextjs-plain',
    desc: 'css preprocessor',
    type: 'other',
  },
  {
    name: 'framer',
    icon: 'framermotion-original',
    desc: 'website builder & cms',
    type: 'html',
  },
  // {
  //   name: 'cypress',
  //   photo: cypress,
  //   desc: 'js e2e testing library',
  //   type: 'test',
  // },
  // {
  //   name: 'wordpress',
  //   icon: 'wordpress-plain',
  //   desc: 'website builder',
  //   type: 'html',
  // },

].map((skill, i) => ({...skill, index: i + 1}));

const CATEGORIES = ["all", "html", "js", "styling", "other"];

class SkillsContainer extends React.Component {

  constructor() {
    super();

    this.state = {
      category: 'all',
      clickedOn: null
    };

    this.setCategory = this.setCategory.bind(this);
    this.setClickedOn = this.setClickedOn.bind(this);
  }

  setCategory(category) {
    this.setState({category});
    this.setState({clickedOn: null});
  }

  setClickedOn(clickedOn) {
    this.setState({clickedOn});
  }

  render() {
    return (
      <section id="skills" className="resume-section">
        <div className="flex-line">
          <div className="flex-left">
            <h1>skills</h1>
          </div>
          <Tabs
            tabs={this.props.categories}
            tabState={this.state.category}
            setCategory={this.setCategory}
            filter={true}
          />
        </div>
          <Skills
            skills={this.props.skills}
            tabState={this.state.category}
            set={this.setClickedOn}
            clickHover={this.state.clickedOn}
          />
      </section>
    );
  }
}

SkillsContainer.defaultProps = {
  skills: SKILLS,
  categories: CATEGORIES
};

export default SkillsContainer;
