import React from 'react';
import c3 from 'c3';

export default class BarChart extends React.Component {
  
    constructor() {
      super();
      
      this.updateData = this.updateData.bind(this);
    }
    
    componentDidMount() {
		this.updateData();
    }
  
    updateData() {
        c3.generate({
            size: {
                height: this.props.height,
            },
            tooltip: {
                show: false
            },
            padding: {
                top: 5,
                bottom: 68,
                left: 130,
                right: 40},
            axis: {
                x: {
                    type: 'category',
                    tick: { outer: false, width: 130},
                },
                y: {
                    min: 0,
                    max: 2500,
                    padding: {bottom: 0, top: 0},
                    tick: { outer: false, count: 6  },
                    label: { text: "Sodales Nunc",  position: 'outer-center'},
                },
                rotated: this.props.rotated
            },
            bindto: '#' + this.props.id,
            data: {
                json: this.props.data,
                color:
                    function (color, d) {
						return ['#4cd964','#4000C0', '#24c1ed', '#EF6670', '#ffcc00'][d.index % 5];
                    },
                type: 'bar',
                keys: {
                    x: 'type',
                    value: ['value']
                },
            },
            grid: {
                focus:{show: false},
                y: { show: true }
            },
            legend: {show: false}
        });
    }
  
    render() {
        return (
            <div id={this.props.id}/>
        );
    }
}