import React from 'react';
import Hobby from './Hobby.jsx';

class Hobbies extends React.Component {
  
  get hobbies() {
    return ['calisthenics', 'reading', 'Drawing', 'cooking', 'video editing'];
  }
  
  renderHobbies() {
    return this.hobbies.map((hobby, i) => (
      <Hobby key={i} hobby={hobby}/>
    ));
  }
  
  render() {
    return (
      <section id="hobbies" className="resume-section">
        <div className="flex-line">
          <h1>hobbies</h1>
        </div>
        <ul className="hobbies section-content flex-wrap">
          {this.renderHobbies()}
        </ul>
      </section>
    );
  }
}

export default Hobbies;