import React from 'react';

import SkillsContainer from './../components/skills/SkillsContainer.jsx';
import Education from './../components/education/Education.jsx';
import Hobbies from './../components/hobbies/Hobbies.jsx';
import Experience from './../components/experience/Experience.jsx';
import Projects from './../components/projects/Projects.jsx';

class Body extends React.Component {
  render() {
    return(
      <div className="resume">
        <div className="resume-content">
          <div className="resume-section">
            <div className="flex-line">
              <div className="flex-left">
                <h1>design portfolio website</h1>
                {/*<div id="new-banner">*/}
                {/*  <div className="letter">n</div>*/}
                {/*  <div className="letter">e</div>*/}
                {/*  <div className="letter">w</div>*/}
                {/*  new*/}
                {/*</div>*/}
              </div>
              <div className="flex-right">
                <a href="https://websitedevsite.webflow.io/" target="_blank" rel="noreferrer">
                  <div id="link-instructions">
                    <h2>click to open</h2>
                    <ion-icon name="open-outline" id="open-icon" />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <SkillsContainer />
          <Experience />
          <Projects />
          <Education />
          <Hobbies />
        </div>
      </div>
    );
  }
}

export default Body;
