import React from 'react';

import Album from './Album.jsx';

// import AddDateFinal from './../../../images/instructions/AddDateFinal.png';
// import AddFinal from './../../../images/instructions/AddFinal.png';
// import ConfirmFinal from './../../../images/instructions/ConfirmFinal.png';
// import MovieFinal from './../../../images/instructions/MovieFinal.png';
// import VoteFinal from './../../../images/instructions/VoteFinal.png';
// import VoteTimeFinal from './../../../images/instructions/VoteTimeFinal.png';

const AddDateFinal = 'instructions/AddDateFinal.webp';
const AddFinal = 'instructions/AddFinal.webp';
const ConfirmFinal = 'instructions/ConfirmFinal.webp';
const MovieFinal = 'instructions/MovieFinal.webp';
const VoteFinal = 'instructions/VoteFinal.webp';
const VoteTimeFinal = 'instructions/VoteTimeFinal.webp';

// import whiteIphoneChat from './../../../images/app-store/whiteIphoneChat.png';
// import whiteIphoneIdeas from './../../../images/app-store/whiteIphoneIdeas.png';
// import whiteIphoneMain from './../../../images/app-store/whiteIphoneMain.png';
// import whiteIphoneMore from './../../../images/app-store/whiteIphoneMore.png';
// import whiteIphoneVote from './../../../images/app-store/whiteIphoneVote.png';

const whiteIphoneChat = 'app-store/whiteIphoneChat.webp';
const whiteIphoneIdeas = 'app-store/whiteIphoneIdeas.webp';
const whiteIphoneMain = 'app-store/whiteIphoneMain.webp';
const whiteIphoneMore = 'app-store/whiteIphoneMore.webp';
const whiteIphoneVote = 'app-store/whiteIphoneVote.webp';

// import loginSlide from './../../../images/screenshots/loginSlide.png';
// import location from './../../../images/screenshots/location.png';
// import venueSearch from './../../../images/screenshots/venueSearch.png';
// import venueSearchResults from './../../../images/screenshots/venueSearchResults.png';
// import venueSearchResultsDetails from './../../../images/screenshots/venueSearchResultsDetails.png';
// import movies from './../../../images/screenshots/movies.png';
// import events from './../../../images/screenshots/events.png';
// import outingActivities from './../../../images/screenshots/outingActivities.png';
// import outingMap from './../../../images/screenshots/outingMap.png';
// import dateTimePickerPopup from './../../../images/screenshots/dateTimePickerPopup.png';
// import outingPage from './../../../images/screenshots/outingPage.png';
// import voteTime from './../../../images/screenshots/voteTime.png';
// import placesResults from './../../../images/screenshots/placesResults.png';
// import timeResults from './../../../images/screenshots/timeResults.png';

const loginSlide = 'screenshots/loginSlide.webp';
const location = 'screenshots/location.webp';
const venueSearch = 'screenshots/venueSearch.webp';
const venueSearchResults = 'screenshots/venueSearchResults.webp';
const venueSearchResultsDetails = 'screenshots/venueSearchResultsDetails.webp';
const movies = 'screenshots/movies.webp';
const events = 'screenshots/events.webp';
const outingActivities = 'screenshots/outingActivities.webp';
const outingMap = 'screenshots/outingMap.webp';
const dateTimePickerPopup = 'screenshots/dateTimePickerPopup.webp';
const outingPage = 'screenshots/outingPage.webp';
const voteTime = 'screenshots/voteTime.webp';
const placesResults = 'screenshots/placesResults.webp';
const timeResults = 'screenshots/timeResults.webp';

class Albums extends React.Component {

  get albums() {
    return [
      {
        type: 'screenshots',
        width: 225,
        height: 450,
        ratio: 0.5,
        photos: [
          loginSlide,
          location,
          venueSearch,
          venueSearchResults,
          venueSearchResultsDetails,
          movies,
          events,
          outingActivities,
          outingMap,
          dateTimePickerPopup,
          outingPage,
          voteTime,
          placesResults,
          timeResults
        ],
        alts: [
          'loginSlide',
          'location',
          'venueSearch',
          'venueSearchResults',
          'venueSearchResultsDetails',
          'movies',
          'events',
          'outingActivities',
          'outingMap',
          'dateTimePickerPopup',
          'outingPage',
          'voteTime',
          'placesResults',
          'timeResults'
        ]
      },
      {
        type: 'instructions',
        width: 225,
        height: 400,
        ratio: 0.5625,
        photos: [
          AddFinal,
          AddDateFinal,
          MovieFinal,
          VoteFinal,
          VoteTimeFinal,
          ConfirmFinal,
        ],
        alts: [
          'AddFinal',
          'AddDateFinal',
          'MovieFinal',
          'VoteFinal',
          'VoteTimeFinal',
          'ConfirmFinal'
        ]
      },
      {
        type: 'app-store',
        width: 225,
        height: 400,
        ratio: 0.5625,
        photos: [
          whiteIphoneMain,
          whiteIphoneIdeas,
          whiteIphoneVote,
          whiteIphoneMore,
          whiteIphoneChat
        ],
        alts: [
          'whiteIphoneMain',
          'whiteIphoneIdeas',
          'whiteIphoneVote',
          'whiteIphoneMore',
          'whiteIphoneChat'
        ]
      },
    ];
  }

  renderAlbums() {
    return this.albums.map((album, i) => (
      <Album
        key={i}
        index={i}
        type={album.type}
        photos={album.photos}
        width={album.width}
        height={album.height}
        alts={album.alts}
        aspectRatio={album.ratio}
      />
    ));
  }

  render() {
    return (
      <div className="exp-section">
        {this.renderAlbums()}
      </div>
    );
  }
}

export default Albums;
