import React from 'react';
import _ from 'underscore';

import StatisticsCard from './../components/cards/StatisticsCard.jsx';
import ChartCard from './../components/cards/ChartCard.jsx';

class WebService3 extends React.Component {

  formatSplineData(data) {
    const { x_axis, vals } = data;
    const data1 = x_axis.map((x, i) => ({date : new Date(x), value: vals[i]}))
      .sort((a, b) => a.date - b.date)
      console.log(data1)
    return data1.map((point, i) => (
      { date: point.date,
        value: data1.slice(0, i + 1).reduce(function(total, num){return total + num.value}, 0)}));
  }

  formatMultiSplineData(data) {
    const dates = _.uniq(_.flatten(_.map(data, geneHistory => (_.keys(geneHistory))))).sort((a,b) => new Date(a) - new Date(b));
    const columns = _.keys(data).map(gene => ([gene, ...dates.map(date => data[gene][date] || 0)]))
      return [['date', ...dates.map(date => new Date(date))], ...columns];
  }
  
  render() {
    return (
      <div id="wbs3" className="page-content">
        <div className="nav-container">
          <div className="nav">Web Service #3</div>
          <div className="hero"/>
        </div>
        <div className="rows-2">
          <div className="row cards-2">
            <StatisticsCard rows={_.map(this.props.numStats, (key, i) => ({number: key, text: i})).sort((a,b) => a.text.length)}/>
            <ChartCard type="cloud" title="Blandit Aliquet" chart={{id:'cloud', data: this.props.search}} />
          </div>
          <div className="row cards-2">
            <ChartCard type="spline" title="Dolor Ornare Id" format={this.formatSplineData}
                       rawData={this.props.userGrowth} chart={{id:"dui-nec-bland", dateFormat: '%b %Y', label: "Dui nec Bland"}} />
            <ChartCard type="spline" title="Semper Rhoncus" format={this.formatMultiSplineData}
                       rawData={this.props.history}
                       chart={{id:"eget-el", subtype: "multiline", dateFormat: '%b %Y', label: "Eget el Ornare"}}/>
          </div>
        </div>
      </div>
    );
  }
}

export default WebService3;