import React from 'react';

// import tallyWBackground from '../../../media/tally/tallyWBackground.png';
// import homeScreen from '../../../media/tally/homeScreen.png';

import { getImageUrl } from '../../../utils';

const tallyWBackground = 'tally/tallyWBackground.png';
const homeScreen = 'tally/homeScreen.png';

class Screen extends React.Component {

  get multipleMemes() {
    return (
      <div className="multi-meme-pack">
        {this.props.memes[0].picture.map((pic, i) => (
          <img
            key={i}
            className="meme-picture"
            alt={pic + '-phone-image'}
            src={getImageUrl(`gifs/${pic}.gif`)}
          />
        ))}
      </div>
    );
  }

  get memes() {
    if (this.props.static) return null;
    return this.props.memes.map((meme, i) => {
      const memes = Array.isArray(meme.picture) ? this.multipleMemes : (
        <img
          id={meme.picture + '-phone-image'}
          alt={meme.picture + '-phone-image'}
          className="meme-picture"
          src={getImageUrl(`gifs/${meme.picture}.gif`)}
        />
      );
      return (
        <div className="meme-img" key={i}>
          <div className="meme-text-table">
            <div className="meme-text">{meme.text}</div>
          </div>
          {memes}
        </div>
      );
    });
  }

  get content() {
    if (this.props.static) {
      return (
        <img
          id="phone-home-screen"
          alt="phone-home-screen"
          src={getImageUrl(homeScreen)}
        />
      );
    } else {
      return (
        <div className="phone-screen-memes">
          <div className="permalink-header">
            <div className="account-group">
              <img
                className="avatar-img"
                alt="avatar-img"
                src={getImageUrl(tallyWBackground)}
              />
              <div className="account-text">
                <span className="fullNameGroup">Tally</span>
                <span className="username">@tally_app</span>
              </div>
            </div>
            <div className="follow-bar">
              <a href="https://twitter.com/intent/user?screen_name=tally_app" target="_blank" rel="noreferrer">
                <div className="follow-button">
                  {/*<i className="ion-person-add"/>*/}
                  <ion-icon name="logo-twitter"/>
                </div>
              </a>
            </div>
          </div>
          <div className="column">
            {this.memes}
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div
        key={this.props.active}
        className={`phone-screen phone-screen-${this.props.direction}`}
      >
        {this.content}
      </div>
    );
  }
}

export default Screen;
