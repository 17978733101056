import React from 'react';

import { getImageUrl } from '../../utils';

// import appStore from '../../media/store/white/appStore.png';
// import googlePlayStore from '../../media/store/white/appStorePlay.png';

// const appStore = 'store/white/appStore.png';
const appStore2 = 'store/white/appStore2.png';
const googlePlayStore = 'store/white/googlePlayStore.png';

class DownloadStripe extends React.Component {
  render() {
    return (
      <div id="download-stripe">
        <div className="button-background">
          <img
            className="download-photo"
            src={getImageUrl(appStore2)}
          />
        </div>
        <div className="button-background">
          <img
            className="download-photo"
            src={getImageUrl(googlePlayStore)}
          />
        </div>
      </div>
    );
  }
}

export default DownloadStripe;
