import React from 'react';

import SplineChart from './../charts/SplineChart.jsx';
import BarChart from './../charts/BarChart.jsx';
import StackChart from './../charts/StackChart.jsx';
import Cloud from './../cloud/Cloud.jsx';

export default class ChartCard extends React.Component {
  
  get chart() {
    let data;
    const height = 390;
      const { chart, type, format, rawData } = this.props;
      if (format) {
        data = format(rawData)}
      switch (type) {
        case 'bar':
          return <BarChart {...chart} data={data} height={height}/>;
        case 'stack':
          return <StackChart {...chart} data={data} height={height}/>;
        case 'spline':
          return <SplineChart {...chart} data={data}  height={height}/>;
        case 'cloud':
          return <Cloud {...chart}/>;
        default:
          return null;
      }
  }
  
  render() {
    return (
      <div className="large-card card header-card">
        <div className="card-header">{this.props.title}</div>
        <div className="card-content">
          {this.chart}
        </div>
      </div>
    );
  }
}
 
 
 
  