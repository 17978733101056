import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import Material from './material/Material.js';
import DraftKings from './dk/DraftKings.jsx';
import Dashboard from './dashboard/Dashboard.jsx';
import { TallyWebsite, TallyTerms, TallyPrivacy } from './tally-website/TallyWebsite.jsx';
// import Planche from './components/planche/Planche.jsx';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Redirect,
  Navigate
} from "react-router-dom";

import './css/App.scss';
import './css/reset.css';

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" exact element={<App />}/>
      <Route path="/material-db" element={<Material />}/>
      <Route path="/draft-kings" element={<DraftKings />}/>
      <Route path="/dashboard" element={<Dashboard />}/>
      <Route path="/tally-website" element={<TallyWebsite />}/>
      <Route path="/tally-website/privacy" element={<TallyPrivacy />}/>
      <Route path="/tally-website/terms" element={<TallyTerms />}/>
      {/*<Route path="*" element={<App />}/>*/}
      <Route path="*" element={<Navigate replace to="/" />}/>
    </Routes>
  </Router>,
  document.getElementById('root')
);
