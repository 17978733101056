import React from 'react';
class StatisticCard extends React.Component {

  get statsRows() {
    return this.props.rows.map((stat, i) => (
      <li key={i}>{stat.number + " " + stat.text.replace('num', '').replace(/_/g, ' ')}</li>
    ));
  }
  
  get stats() {
	  const { statistics, date } = this.props;
	  return (
      <div className="stats-card-content">
        <div className="stats-number">{statistics.number}</div>
        <div className="stats-text">{statistics.text}</div>
          {date ? <div className="stats-label">(Last Updated: August 28, 2017)</div>: ''}
      </div>
    );
  }
  
  render() {
    let content;
    const { statistics, rows} = this.props;
      if (rows && statistics) {
        content = (
        	<div className="stats-card-content" id="wbs2-column"
				 style={{justifyContent: "space-around", padding: '0'}}>
				{this.stats}
				<div className="stats-card-content">
				    <ul className="stats-column">
					{this.statsRows}
				    </ul>
				</div>
			</div>
        );
      }
      else if (rows) {
        content = (
            <div className="stats-card-content">
              <ul className="stats-column">{this.statsRows}</ul>
            </div>);
      } else if (statistics) {
        content = this.stats;
      }
    return (
      <div className="card stats-card large-card">
        {content}
      </div>
    );
  }
}

export default StatisticCard;
