import React from 'react';
import _ from 'underscore';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Skill from './Skill.jsx';

class Skills extends React.Component {

  constructor() {
    super();

    this.filter = this.filter.bind(this);
  }

  filter(skill) {
    const state = this.props.tabState;
    return (skill.type === state) || (state === 'all');
  }

  get skills() {
    const filteredSkills = this.props.skills.filter(this.filter);
    return (
      <TransitionGroup
        component="ul"
        className="skill-list"
      >
        {filteredSkills.map((skill, i) => {
          return (
            <CSSTransition
              key={skill.name}
              timeout={1000}
              classNames="skillTransition"
            >
              <Skill
                skill={skill}
                key={skill.index}
                set={this.props.set}
                clickHover={this.props.clickHover}
              />
            </CSSTransition>
          )
        })}
      </TransitionGroup>
    )
  }

  renderSkills() {
    const filteredSkills = this.props.skills.filter(this.filter);
    // console.log(filteredSkills)
    return this.props.skills.map((skill, i) => {
      // console.log(!!(_.where(filteredSkills, {...skill})).length)
      return (
        <CSSTransition
          key={i}
          timeout={800}
          classNames="skillTransition"
          in={!!(_.where(filteredSkills, {...skill})).length}
        >
          <Skill
            key={skill.index}
            skill={skill}
            set={this.props.set}
            clickHover={this.props.clickHover}
          />
        </CSSTransition>
      )
    });
  }

  render() {
    // return (
    //   <div className="skill-list-container">
    //     <div className="grower"/>
    //     {this.skills}
    //     <div className="grower"/>
    //   </div>
    // );
    return this.skills;
    // return (
    //   <ul className="skill-list flex-wrap">
    //     {this.skills}
    //   </ul>
    // );
  }
}

export default Skills;
