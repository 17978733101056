import React from 'react';

import HoverBox from './HoverBox.jsx';

class Skill extends React.Component {

  constructor() {
    super();

    this.state = {
      hover: false
    };

    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    this.renderDesc = this.renderDesc.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  onMouseEnterHandler() {
    if (window.innerWidth < 925) return;
    this.setState({hover: true});
  }

  onMouseLeaveHandler() {
    if (window.innerWidth < 925) return;
    this.setState({hover: false});
  }

  handleClick() {
    if (window.innerWidth >= 925) return;
    this.setState({hover: false});
    this.props.set(this.props.skill.name);
  }

  renderDesc() {
    if (this.state.hover) {
      return <HoverBox item={this.props.skill} class="hover" /> ;
    }
    if (this.props.clickHover === this.props.skill.name) {
      return <HoverBox item={this.props.skill} class="click" />;
    }
  }

  render() {
    let image;
    const { photo } = this.props.skill;
      if (photo) image = <img src={photo} alt={this.props.skill.name}/>;
      else image = <i className={`devicon-${this.props.skill.icon} colored`} />;
    return (
      <li
        key={this.props.skill.index}
        className="logo-icon"
        onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler}
        onClick={this.handleClick}
      >
        {image}
        {this.renderDesc()}
      </li>
    );
  }
}

export default Skill;
