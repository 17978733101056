import React from 'react';

class Hobby extends React.Component {
  render() {
    return (
      <li className="hobby">
        <h3>{this.props.hobby}</h3>
      </li>
    );
  }
}

export default Hobby;