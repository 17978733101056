import React from 'react';
import _ from 'underscore';
import './reset.css';
import 'c3/c3.css';
import './App.scss';

import {WBS2, WBS3, WBS1} from './data/data';

import WebService1 from './layout/WebService1.jsx';
import WebService3 from './layout/WebService3.jsx';
import WebService2 from './layout/WebService2.jsx';

export default class Dashboard extends React.Component {

  constructor() {
    super();

    this.state = {
      wbs1: {
          patientsSequenced: WBS1.seq,
          freq: WBS1.freq,
          history: WBS1.history,
          vars: WBS1.vars,
      },
      wbs2: {
          users: WBS2.users,
          visits: {
            x_axis: WBS2.visits.x_axis,
            vals: WBS2.visits.vals
          },
          search: WBS2.search,
          numStats: _.omit(WBS2, 'visits', 'growth', 'search', 'users')
      },
      wbs3: {
        numStats: _.omit(WBS3, 'terms', 'history', 'growth'),
        history: WBS3.history,
        search: WBS3.terms,
        userGrowth: {x_axis: WBS3.growth.x_axis, vals: WBS3.growth.vals}
      }
    };
  }

  render() {
    return (
      <div id="dashboard">
        <WebService1 {...this.state.wbs1}/>
        <div className="page container">
          <WebService2 {...this.state.wbs2}/>
          <WebService3 {...this.state.wbs3}/>
        </div>
      </div>
    );
  }
}

