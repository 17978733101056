import React from 'react';

import Home from './pages/Home.jsx';
// import About from './pages/About.jsx';
// import ContactUs from './pages/ContactUs.jsx';
import Privacy from './pages/Privacy.jsx';
import Terms from './pages/Terms.jsx';
import Footer from "./layouts/Footer";

import './styles/App.scss';

const PageContainer = ({ children }) => (
  <div id="tally-website">
    <div className="page main-page">
      <div className="page-content">
        {children}
      </div>
    </div>
    <Footer />
  </div>
);

export const TallyWebsite = () => (
  <PageContainer>
    <Home />
  </PageContainer>
);

export const TallyTerms = () => (
  <PageContainer>
    <Terms />
  </PageContainer>
);

export const TallyPrivacy = () => (
  <PageContainer>
    <Privacy />
  </PageContainer>
);


  //   <div className="page main-page">
  //     <div className="page-content">
  //       <Routes>
  //         <Route path="/" element={ <Home/> } />
  //         {/*<Route path="/about" element={ <About /> }/>*/}
  //         {/*<Route path="/contact" element={ <ContactUs /> }/>*/}
  //         <Route path="/privacy" element={ <Privacy /> }/>
  //         <Route name="terms" path="/terms" element={ <Terms /> }/>
  //         {/*<Route path="*" component={ NotFound } />*/}
  //       </Routes>
  //     </div>
  //   </div>
  //   <Footer/>
  // </Router>
