import React from 'react';
import { Waypoint } from 'react-waypoint';
import ScrollAnim from 'rc-scroll-anim';

import { SECTIONS } from '../../../pages/Home.jsx';
import Phone from '../phone/Phone.jsx';

import { getImageUrl } from '../../../utils';

const ScrollParallax = ScrollAnim.Parallax;

class Section extends React.Component {

  constructor() {
    super();

    this.state = {width: window.innerWidth || document.body.clientWidth};

    this.handleEnter = this.handleEnter.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({width: window.innerWidth || document.body.clientWidth});
    });
  }

  // get memes() {
  //   return this.props.section.memes.map((meme, i) => (
  //     <div key={i} className="meme-img">
  //       <div className="meme-text-table">
  //         <div className="meme-text">{meme.text}</div>
  //       </div>
  //       <img
  //         className="meme-picture"
  //         src={`/gifs/${meme.picture}.gif`}
  //       />
  //       {/*<div className="meme-picture box"></div>*/}
  //     </div>
  //   ));
  // }

  get paragraphs() {
    return this.props.section.paragraphs.map((p, i) => (
      <p key={i}>{p}</p>
    ));
  }

  get background() {
    const { imageSrc, id } = this.props.section.background;
    return {
      // backgroundImage: `url(${imageSrc})`,
      backgroundImage: `url(${getImageUrl(imageSrc)})`,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    };
  }

  get backgroundImages() {
    return this.props.section.background.absolutePictures.map((img, i) => (
      <img
        id={img.id}
        key={i}
        src={getImageUrl(img.src)}
        className="absolute-picture"
      />
    ));
  }

  handleEnter(e) {
    const { id } = this.props.section;
    if (e.currentPosition === 'inside' && e.previousPosition === 'below') {
      this.props.setScrollUp();
      this.props.handleSectionEnter(id);
    }
  }

  handleLeave(e) {
    const { id } = this.props.section;
    const prev = Number(id.charAt(id.length - 1)) - 1;
    if (e.currentPosition === 'below' && e.previousPosition === 'inside') {
      this.props.setScrollDown();
      this.props.handleSectionEnter(id.slice(0, -1) + prev);
    }
  }

  render() {
    let mobileWidthPhone;
    const {last, section, staticPhone} = this.props;
    const {inlineImg, title, id} = section;
    const activeMemes = SECTIONS.map(section => section.memes);

    if (this.state.width <= 800) {
      mobileWidthPhone = (
        <Phone
          active={id}
          fixed={false}
          mobile={true}
          direction={this.props.direction}
          activeMeme={{memes: activeMemes[Number(id.charAt(id.length - 1)) - 1]}}
        />
      );
    }

    const phone = staticPhone && last ? (
      <Phone
        active="body-section-4"
        direction={this.props.direction}
        fixed={false}
        activeMeme={{memes: activeMemes[3]}}
      />
    ): null;
    return (
      <div
        id={id}
        style={this.background}
        className="section-container parallax full-window-height"
      >
        <Waypoint onEnter={this.handleEnter} onLeave={this.handleLeave} />
        <div className="parallax-layer">
          <ScrollParallax
            style={{ transform: 'translateY(80px)', height: '100%'}}
            animation={{ translateY: -80, ease: 'linear', playScale: [0, 1] }}
          >
            {this.backgroundImages}
          </ScrollParallax>
        </div>
        <section className="section">
          {inlineImg ? (
            <img
              id={inlineImg.id}
              src={getImageUrl(inlineImg.src)}
            />
          ) : null}
          <div className="section-imgs intro-imgs">
            {mobileWidthPhone || phone}
          </div>
          <div className="section-text">
            <h3>{title}</h3>
            {this.paragraphs}
          </div>
        </section>
      </div>
    );
  }
}

export default Section;
