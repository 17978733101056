import React from 'react';

import Tab from './Tab.jsx';

class Tabs extends React.Component {

  get tabs() {
    return this.props.tabs.map((tab, i) => (
      <Tab
        key={i}
        tab={tab}
        tabState={this.props.tabState}
        setCategory={this.props.setCategory}
      />
    ));
  }

  render() {
    return (
      <div className="flex-right">
        {this.props.filter ? <h2>filter by</h2> : null}
        <div className="tabs">
          {this.tabs}
        </div>
      </div>
    );
  }
}

Tabs.defaultProps = {
  filter: true,
};

export default Tabs;
