import React from 'react';
import _ from 'underscore';

import TableCard from './../components/cards/TableCard';
import ChartCard from './../components/cards/ChartCard.jsx';
import StatisticsCard from './../components/cards/StatisticsCard.jsx';

class WebService2 extends React.Component {

  formatVisits(data) {
    const { x_axis, vals } = data;
    return x_axis.map((x, i) => ({date : new Date(x), value: vals[i]}))
                        .sort((a, b) => a.date - b.date)
  }

  render() {
    return (
      <div id="wbs2" className="page-content">
        <div className="nav-container">
          <div className="nav">Web Service #2</div>
          <div className="hero"/>
        </div>
        <div className="rows-2" >
          <div className="row cards-2">
            <StatisticsCard rows={_.map(this.props.numStats, (key, i) => ({number: key, text: i})).sort((a,b) => a.text.length)}/>
            <TableCard title={'Faucibus Nam Nec'} data={this.props.search} format="terms" head={['Term', 'Count', 'Pass']}/>
          </div>
          <div className="row cards-2">
            <ChartCard type="spline" title="Venenatis Viverra" format={this.formatVisits}
                       rawData={this.props.visits} chart={{id:"habitasse", dateFormat: '%b %e', label: "Habitasse Platea"}} />
            <TableCard title="Accumsan Et Ex" format="users" data={this.props.users}
                       head={['User', 'Last Login', 'Browser']}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default WebService2;