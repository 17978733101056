import React from 'react';

import TableCard from './../components/cards/TableCard';
import ChartCard from './../components/cards/ChartCard.jsx';
import StatisticsCard from './../components/cards/StatisticsCard.jsx';

export default class WebService1 extends React.Component {

  formatSeqData(data) {
    return data.x_axis.map((x, i) => (
      { date: new Date(x),
          "Time Period 1": data.t1[i],
          "Time Period 2": data.t2[i],
		  "Time Period 3": data.t3[i]}
    ));
  }

  formatBarData(data) {
    const keys = Object.keys(data);
    return keys.map((key, i) => (
      {
        type: key,
        value: data[key]
      }
    )).sort((a, b) => b.value - a.value)
  }

  render() {
    const { patientsSequenced, vars, history, freq, lastUpdated } = this.props;
    return (
      <div id="wbs1" className="page">
        <div className="nav-container">
          <div className="nav">Web Service #1</div>
          <div className="hero"/>
        </div>
        <div className="rows-2">
          <div className="row cards-3">
            <StatisticsCard date={true}
              statistics={{number: (patientsSequenced ? patientsSequenced: '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                            text: 'Duis Lobortis', label: lastUpdated}} />
            <ChartCard type="bar" title="Rhoncus: Sed Viverra" format={this.formatBarData}
                        chart={{id:"freq", rotated: true}} rawData={freq}
            />
            <TableCard title={`Ullamcorper Ante`} data={vars}
                        head={['Category',  'Count']}/>
          </div>
          <div className="row">
            <ChartCard type="stack" title="Consectetur Enim Bibendum" {...this.props.ready} format={this.formatSeqData}
                       chart={{id:"enim-bibendum"}} rawData={history}
            />
          </div>
        </div>
      </div>
    );
  }
}