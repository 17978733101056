import React from 'react';

class Details extends React.Component {
  
  get headers() {
    return this.props.headers.map((h, i) => <h2 key={i}>{h}</h2>);
  }
  
  get paragraphs() {
    return this.props.paragraphs.map((p, i) => <p key={i}>{p}</p>);
  }
  
  render() {
    return (
      <div id="exp-details" className="exp-section">
        <div className="details-header">
          <span className="bold-500"> 
          {this.headers}
          </span>
        </div>
        <div>
          {this.paragraphs}
        </div>
      </div>
    );
  }
}

export default Details;