import _ from 'underscore';
import moment from 'moment';

export const WBS1 = {
	"seq": 13337,
	freq: _.reduce(["Quisque", "At Ligula", "Placerat", "Accumsan", "Etiam Quis",
			"Lobortis", "Donec",  "Vestibulum", "Ultricies", "Pellentesque"],
		function(obj, word){
			obj[word] = Math.floor(500 + Math.random() * 2000);
			return obj;
		}, {}),
	"history": {
		"t1": [
			72,
			180,
			195,
			242,
			85,
			240,
			427,
			188,
			320,
			381,
			225,
			347,
			189,
			185,
			60,
			45,
			38,
			14,
			9,
			5,
			1,
			0,
			0,
			3,
			0,
			1,
			1,
			0,
			0,
			0,
			0,
			0,
			1,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0
		],
		"t2": [
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			10,
			65,
			134,
			180,
			259,
			299,
			320,
			248,
			285,
			287,
			320,
			384,
			409,
			326,
			422,
			360,
			329,
			490,
			500,
			523,
			463,
			310,
			463,
			325,
			229,
			281,
			397,
			235,
			222,
			132,
			89,
			72,
			31,
			28,
			10,
			0,
			0,
			0,
			0
		],
		"t3": [
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			10,
			140,
			173,
			388,
			494,
			527,
			536,
			404,
			482,
			322,
			374,
			402,
			412
		],
		"x_axis": [
			"2013 7",
			"2013 8",
			"2013 9",
			"2013 10",
			"2013 11",
			"2013 12",
			"2014 1",
			"2014 2",
			"2014 3",
			"2014 4",
			"2014 5",
			"2014 6",
			"2014 7",
			"2014 8",
			"2014 9",
			"2014 10",
			"2014 11",
			"2014 12",
			"2015 1",
			"2015 2",
			"2015 3",
			"2015 4",
			"2015 5",
			"2015 6",
			"2015 7",
			"2015 8",
			"2015 9",
			"2015 10",
			"2015 11",
			"2015 12",
			"2016 1",
			"2016 2",
			"2016 3",
			"2016 4",
			"2016 5",
			"2016 6",
			"2016 7",
			"2016 8",
			"2016 9",
			"2016 10",
			"2016 11",
			"2016 12",
			"2017 1",
			"2017 2",
			"2017 3",
			"2017 4",
			"2017 5",
			"2017 6",
			"2017 7",
			"2017 8"
		]
	},
	"vars": _.reduce(["Lorem ipsum efficitur", "Suspendisse", "tempus porta pharetra", "mauris sit amet", "feugia",
			"Nullam metus", "lectus egestas lacinia", "ultricies", "auctor vitae", "Phasellus maximus", "urna magna et", "eu arcu varius",
			"vulputate sociosqu", "Donec at lacus"],
		function(obj, word){
			obj[word] = Math.floor(Math.random() * 50);
			return obj;
		}, {}),
};

export const WBS2 = {
	"Lectus Egtas": 129,
	Consecteur: 676,
	Volutpat: 156,
	users: [
		{
			user: "Quisque Suscipit",
			"Log In Date": moment().subtract(52, 'seconds'),
			browser: "Internet Explorer"
		},
		{
			user: "Urna Dignissim",
			"Log In Date": moment().subtract(1, 'days'),
			browser: "Chrome"
		},
		{
			user: "Sed Viverra",
			"Log In Date": moment().subtract(1, 'days'),
			browser: "Chrome"
		},
		{
			user: "Pharetra Volutpat",
			"Log In Date": moment().subtract(22, 'hours'),
			browser: "Chrome"
		},
		{
			user: "Tincidunt Dignissim",
			"Log In Date": moment().subtract(4, 'hours'),
			browser: "Firefox"
		},
		{
			user: "Ante Rutrum",
			"Log In Date": moment().subtract(32, 'minutes'),
			browser: "Safari"
		},
		{
			user: "Donec At Lacus",
			"Log In Date": moment().subtract(1, 'hours'),
			browser: "Chrome"
		},
		{
			user: "Feugia Nullam",
			"Log In Date": moment().subtract(1, 'days'),
			browser: "Safari"
		},
		{
			user: "Dignissim Non",
			"Log In Date": moment().subtract(3, 'days'),
			browser: "Internet Explorer"
		},
		{
			user: "Himenaeos Suspendisse",
			"Log In Date": moment().subtract(15, 'hours'),
			browser: "Internet Explorer"
		},
		{
			user: "Leo Vel Porttitor",
			"Log In Date": moment().subtract(2, 'days'),
			browser: "Internet Explorer"
		},
	].sort((a,b) => new Date(a) - new Date(b)),
	visits: {
		"vals": [
			18,
			16,
			20,
			0,
			0,
			21,
			20,
			15,
			27,
			18,
			1,
			0,
			19,
			19,
			28,
			16,
			23,
			2,
			0,
			17,
			26,
			25,
			20,
			25,
			3,
			2,
			20,
			24,
			27,
			19,
		],
		"x_axis": [
			"Aug 30",
			"Aug 29",
			"Aug 28",
			"Aug 27",
			"Aug 26",
			"Aug 25",
			"Aug 24",
			"Aug 23",
			"Aug 22",
			"Aug 21",
			"Aug 20",
			"Aug 19",
			"Aug 18",
			"Aug 17",
			"Aug 16",
			"Aug 15",
			"Aug 14",
			"Aug 13",
			"Aug 12",
			"Aug 11",
			"Aug 10",
			"Aug 09",
			"Aug 08",
			"Aug 07",
			"Aug 06",
			"Aug 05",
			"Aug 04",
			"Aug 03",
			"Aug 02",
			"Aug 01"
		]
	},
	search: _.reduce(["Curabitur Sed", "justo Sem", "Mollis Egestas", "Lacinia", "Vestibulum", "Ultricies",
					  "Efficitur Dui", "In Maecenas", "Interdum", "Velit Id Ipsum"],
		function(obj, word){
			obj.push({
				label: word,
				num: Math.floor(Math.random() * 6 + Math.random() * 50),
				positive: Math.random() <= .8
			});
			return obj;
		}, []).sort((a, b) => b.num - a.num),
	growth: {
		"vals": [
			5,
			4,
			10,
			2,
			16,
			5,
			4,
			1,
			2,
			4,
			9,
			4,
			17
		],
		"x_axis": [
			"2017 07",
			"2016 07",
			"2017 04",
			"2017 01",
			"2017 03",
			"2016 06",
			"2017 06",
			"2016 09",
			"2016 12",
			"2016 05",
			"2017 08",
			"2017 05",
			"2017 02"
		]
	}
};

export const WBS3 = {
	history: {
		"Lorem": {
			"2015 08": 2,
			"2015 09": 11,
			"2015 10": 3,
			"2015 11": 31,
			"2015 12": 3,
			"2016 01": 11,
			"2016 02": 12,
			"2016 03": 12,
			"2016 04": 6,
			"2016 06": 3,
			"2016 07": 3,
			"2016 08": 4,
			"2016 09": 7,
			"2016 10": 9,
			"2016 11": 5,
			"2016 12": 8,
			"2017 01": 8,
			"2017 02": 6,
			"2017 03": 13,
			"2017 04": 6,
			"2017 05": 16,
			"2017 06": 4,
			"2017 07": 6,
			"2017 08": 7
		},
		"Ipsum": {
			"2015 08": 1,
			"2015 09": 14,
			"2015 10": 46,
			"2015 11": 47,
			"2015 12": 23,
			"2016 01": 16,
			"2016 02": 10,
			"2016 03": 4,
			"2016 04": 6,
			"2016 05": 5,
			"2016 06": 5,
			"2016 07": 17,
			"2016 08": 6,
			"2016 09": 22,
			"2016 10": 15,
			"2016 11": 1,
			"2017 01": 5,
			"2017 02": 21,
			"2017 03": 2,
			"2017 05": 1,
			"2017 06": 2,
			"2017 07": 10,
			"2017 08": 5
		},
		"Dolor": {
			"2015 08": 1,
			"2015 09": 7,
			"2015 10": 15,
			"2015 11": 53,
			"2015 12": 17,
			"2016 01": 13,
			"2016 02": 14,
			"2016 03": 3,
			"2016 04": 4,
			"2016 05": 31,
			"2016 06": 5,
			"2016 07": 9,
			"2016 08": 6,
			"2016 09": 13,
			"2016 10": 17,
			"2016 11": 1,
			"2016 12": 2,
			"2017 01": 4,
			"2017 02": 21,
			"2017 03": 2,
			"2017 05": 2,
			"2017 06": 2,
			"2017 07": 10,
			"2017 08": 4
		},
		"Porta": {
			"2015 06": 1,
			"2015 08": 2,
			"2015 09": 8,
			"2015 10": 8,
			"2015 11": 21,
			"2015 12": 8,
			"2016 01": 9,
			"2016 02": 14,
			"2016 03": 3,
			"2016 04": 6,
			"2016 05": 9,
			"2016 06": 6,
			"2016 07": 6,
			"2016 08": 3,
			"2016 09": 1,
			"2016 11": 1,
			"2016 12": 5,
			"2017 01": 1,
			"2017 03": 1,
			"2017 04": 7,
			"2017 05": 19,
			"2017 06": 4,
			"2017 07": 6,
			"2017 08": 10
		},
		"Sit": {
			"2015 06": 1,
			"2015 08": 2,
			"2015 09": 8,
			"2015 10": 8,
			"2015 11": 7,
			"2016 01": 8,
			"2016 02": 14,
			"2016 03": 4,
			"2016 04": 1,
			"2016 05": 9,
			"2016 06": 2,
			"2016 07": 3,
			"2016 08": 3,
			"2016 09": 12,
			"2016 10": 9,
			"2016 11": 2,
			"2016 12": 2,
			"2017 01": 11,
			"2017 02": 3,
			"2017 03": 13,
			"2017 04": 5,
			"2017 05": 2,
			"2017 06": 1,
			"2017 07": 2,
			"2017 08": 4
		},
		"Amet": {
			"2015 10": 1,
			"2015 11": 7,
			"2016 01": 9,
			"2016 02": 14,
			"2016 03": 5,
			"2016 05": 5,
			"2016 06": 3,
			"2016 07": 2,
			"2016 08": 1,
			"2016 09": 1,
			"2017 01": 2,
			"2017 03": 3,
		},
		"Donec": {
			"2015 09": 4,
			"2015 10": 2,
			"2015 11": 1,
			"2016 01": 1,
			"2016 02": 2,
			"2016 03": 2,
			"2016 04": 4,
			"2016 05": 5,
			"2016 06": 3,
			"2016 07": 2,
			"2016 09": 1,
			"2017 01": 2,
			"2017 03": 3,
			"2017 05": 19,
			"2017 06": 5,
			"2017 07": 6,
			"2017 08": 4,
		},
		"Vitae": {
			"2015 09": 1,
			"2015 10": 2,
			"2015 11": 1,
			"2016 01": 1,
			"2016 02": 8,
			"2016 03": 2,
			"2016 04": 7,
			"2016 06": 2,
			"2016 07": 3,
			"2016 08": 2,
			"2016 09": 1,
			"2016 10": 2,
			"2016 11": 1,
			"2016 12": 8,
			"2017 01": 1,
			"2017 03": 2,
			"2017 04": 12,
			"2017 05": 21,
			"2017 06": 4,
			"2017 07": 4,
			"2017 08": 13
		},
		"Metus": {
			"2015 09": 1,
			"2015 10": 3,
			"2015 11": 1,
			"2016 02": 2,
			"2016 03": 3,
			"2016 04": 1,
			"2016 05": 5,
			"2016 06": 3,
			"2016 07": 2,
			"2016 09": 1,
			"2017 01": 2,
			"2017 03": 3,
			"2017 07": 1
			
			
		},
		
	},
	terms: _.reduce(["Lorem", "ipsum", "dolor", "sit", "amet", "consectetur", "adipiscing", "elit",
		"Suspendisse", "tempus", "porta", "mauris", "sit", "amet", "feugia", "Nullam", "metus", "lectus", "egestas",
		"ultricies", "ultrices", "eu", "auctor", "vitae", "tellus", "Phasellus", "maximus", "urna", "arcu",
		"varius", "vulputate", "Donec", "at", "lacus", "vulputate,", "rutrum", "leo", "vel", "porttitor",
		"Maecenas", "lacus", "augue,", "pulvinar", "quis", "fringilla", "vel", "placerat", "arcu", "Vestibulum",
		"mattis", "dapibus", "pretium", "Ut", "auctor", "ipsum", "vitae", "tristique", "ante", "rutrum",
		"quis.", "Donec", "varius", "urna", "mauris", "rhoncus", "sed", "viverra", "urna", "dignissim", "Curabitur",
		"ut", "mi", "sed", "nulla", "pharetra", "volutpat", "velit", "Mauris", "vehicula", "pretium", "efficitur",
		"Nullam", "aliquam", "elementum", "enim", "at", "pellentesque", "erat", "tempus", "eu", "Mauris", "pellentesque",
		"quam", "nec", "finibus", "pharetra", "mauris", "felis", "efficitur", "sem", "eget", "porttitor", "orci", "arcu",
		"nec", "diam", "Pellentesque", "at", "iaculis", "lectus", "et", "accumsan", "arcu", "Nulla", "non", "tristique",
		"est", "eget", "bibendum", "neque", "Quisque", "quis", "suscipit", "magna", "a", "aliquet", "mi", "Class",
		"aptent", "taciti", "sociosqu", "ad", "litora", "torquent", "per", "conubia", "nostra", "per", "inceptos",
		"himenaeos", "Suspendisse", "potenti", "Maecenas", "vitae", "eros", "tincidunt", "dignissim", "non",
		"et", "urna", "Aenean", "mollis", "neque", "pretium", "vehicula", "ornare", "Ut", "viverra", "lacinia", "lorem",
		"vel", "imperdiet", "mauris", "aliquet", "sed", "Aenean", "neque", "sem", "volutpat", "in", "porttitor",
		"interdum", "lorem", "Sed", "in", "ante"],
		function(obj, word){
			obj[word.slice(0,7)] = Math.floor(Math.random() * 50);
			return obj;
		}, {}),
	"In Porttitor": 21,
	"Pellentesque": 789,
	"Sociosqu": 509,
	"Ad Litora": 33,
	growth: {
		vals: _.flatten([0, Array(25).fill(0).map(function(und){return Math.floor(Math.random() * 32)})]),
		"x_axis": [
			"2015 11",
			"2016 11",
			"2016 06",
			"2017 06",
			"2016 08",
			"2017 08",
			"2015 08",
			"2017 07",
			"2016 07",
			"2015 07",
			"2016 04",
			"2017 04",
			"2015 12",
			"2016 12",
			"2016 10",
			"2016 02",
			"2017 02",
			"2017 05",
			"2016 05",
			"2017 01",
			"2016 01",
			"2017 03",
			"2016 03",
			"2015 09",
			"2016 09",
			"2015 10"
		]
	}
};


