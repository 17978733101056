import React, { Component } from 'react';

import Shoulder from './layout/Shoulder.jsx';
import Body from './layout/Body.jsx';

class App extends Component {
  render() {
    return (
      <div id="tambamon" className="container clearfix">
        <Shoulder/>
        <Body/>
      </div>
    );
  }
}

export default App;


