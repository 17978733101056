import React from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
// import ReactCSSTransitionGroup from 'react-transition-group';

import Details from './details/Details.jsx';
import Links from './links/Links.jsx';
import Albums from './albums/Albums.jsx';

class ExperienceSection extends React.Component {

  get content() {
    const { category, details, photos, links, linkMessage, id } = this.props;
    switch (category) {
      case 'details':
        return (
          <Details {...details} key={0}/>
        );
      case 'photos': {
      // case 'photos': {
        if (photos) {
          return (
            <Albums key={1}/>
          );
        }
        return null;
      }
      case 'links':
        if (links) {
          return (
            <Links
              id={id}
              links={links}
              linkMessage={linkMessage}
            />
          )
        }
        return null;
      default:
        return null;
    }
  }

  get style() {
    const { category, id } = this.props;
    if (category === 'photos' && id === 'tally') return {  marginTop: 0 };
    return {};
  }

  get logo() {
    const { logo, category, id, photos } = this.props;
    if (category === 'photos' && !photos) return null;
    if (category === 'links' && id === 'tally') return null;
    else return <img key={32} id={logo.id} className="section-img" src={logo.src} alt={logo.id} />;
  }

  render() {
    const { id } = this.props;
    return (
      <div id={id + '-section-content'} className="section-content" style={this.style}>
        <ReactCSSTransitionReplace
          transitionName="logoTransition"
          transitionEnterTimeout={600}
          transitionLeaveTimeout={600}
        >
          {this.logo}
        </ReactCSSTransitionReplace>
        <ReactCSSTransitionReplace
          transitionName="cross-fade"
          transitionEnterTimeout={600}
          transitionLeaveTimeout={600}
        >
          {this.content}
        </ReactCSSTransitionReplace>
      </div>
    );
  }
}

export default ExperienceSection;
