import React from 'react';

import Project from './Project.jsx';
import asaScreenshot from './../../images/projects/asaScreenshot.webp';
import kongScreenShot from './../../images/projects/kongScreenShot.webp';
import tallyScreenShot from './../../images/projects/tallyWebsiteScreenshot.webp';
import dashboardScreenshot from './../../images/projects/dashboardScreenshot.webp';

class Projects extends React.Component {

  get projectContent() {
    return [
      {
        name: 'Tally Website',
        image: tallyScreenShot,
        route: 'tally-website',
        alt: 'tally',
        date: '15 jan. 2018',
      },
      {
        name: 'Dashboard',
        image: dashboardScreenshot,
        route: 'dashboard',
        alt: 'dashboard',
        date: '30 aug. 2017',
        git: 'https://gitlab.com/Tamba/dashboard'
      },
      {
        name: 'Material Design DB',
        image: asaScreenshot,
        route: 'material-db',
        alt: 'materialDesignDB',
        date: '12 mar. 2017',
        git: 'https://github.com/tamaccount/Material-Database'
      },
      {
        name: 'Parallax Design',
        image: kongScreenShot,
        route: 'draft-kings',
        alt: 'kongScreenShot',
        date: '17 mar. 2017',
        git: 'https://github.com/tamaccount/DraftKings'
      }
    ];
  }

  get projects() {
    return this.projectContent.map((project, i) => (
      <Project key={i} project={project}/>
    ));
  }

  render() {
    return (
      <section id="projects" className="resume-section">
        <div className="flex-line">
          <h1>projects</h1>
        </div>
        <div className="section-content">
          {this.projects}
        </div>
      </section>
    );
  }
}

export default Projects;

