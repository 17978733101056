import React from 'react';
import { TERMS } from '../components/terms/terms';

class Terms extends React.Component {

  get extensions() {
    return [
      {name: 'roundSlider'},
      {name: 'React Infinite Calendar'},
      {name: 'React iDangerous Swiper'},
      {name: 'Framework7'},
      {name: 'Swiper'},
      {name: 'Growler'},
      {name: 'Ionicions'},
      {name: 'EmojiOne'},
    ];
  }

  get apis() {
    return [
      {name: 'Facebook',
        url: 'https://www.facebook.com/about/basics'},
      {name: 'Gruvi',
        url: 'http://gruvi.tv/privacy-policy/'},
      {name: 'Foursquare',
        url: 'https://foursquare.com/privacy'},
      {name: 'Google Maps',
        url: 'https://www.google.com/policies/privacy/'},
      // {name: 'Crashlytics',
      //   url: 'http://try.crashlytics.com/terms/'},
      // {name: 'Answers',
      //   url: 'https://answers.io/img/privacy.pdf'},
    ];
  }

  get terms() {
    return TERMS.split('\n').map((line, i) => (
      <p key={i}>{line}</p>
    ));
  }

  render() {
    return (
      <div id="terms-page">
        <div className="terms-text">
          <div className="text-block">
            <p className="heading">Terms of Service</p>
            {this.terms}
          </div>
        </div>
      </div>
    );
  }
}

export default Terms;
