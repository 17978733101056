import React from 'react';
import { PRIVACY } from '../components/privacy/privacy';

class Privacy extends React.Component {

  get privacy() {
    return PRIVACY.split('\n').map((line, i) => (
      <p key={i}>{line}</p>
    ));
  }

  render() {
    return (
      <div id="privacy-page">
        <div className="privacy-text">
          <div className="text-block">
            <p className="heading">Privacy Policy</p>
            {this.privacy}
          </div>
        </div>
      </div>
    );
  }
}

export default Privacy;
