import React from 'react';
import $ from "jquery";

import Lobby from './../components/lobby/Lobby.jsx';
import Content from './../layout/Content.jsx';

const zeroth = 'kong_Military.avif';
const first = 'kong_Water.webp';
const second = 'kong_Front.webp';
const third = 'kong_3rdBackMost.webp';
const fourth = 'kong_2ndBackMost.webp';
const fifth = 'kong_Kong.webp';
const sixth = 'kong_BackMost.webp';
const seventh = 'kong_Sky.webp';
const waterClear = 'kongBig_Logo.webp';
const waterGraphic = 'waterGraphic.avif';

const koth = 'words-01.webp';
const become = 'words-02.webp';
const fantasy = 'words-03.avif';

const CDN = 'https://tamba-website-images.s3.amazonaws.com/kong/';

class KongPage extends React.Component {

  componentDidMount() {
    $(window).resize(function() {
      $('#parallax__cover').css('left', 1500 - window.innerWidth/2 + 'px');
    });
  }

  get backgrounds() {
    return [...Array(3)].map((background, i) => (
      <div key={i} className={"background-color background-" + i}/>
    ));
  }

  get content() {
    return [seventh, sixth, fifth, fourth, third, second, first, zeroth];
  }

  get parallaxes() {
    return this.content.map((img, i) => (
      <div
        key={img + '_img'}
        className={"parallax__layer parallax__layer__" + (i)}
      >
        <img
          ref={img + '_img'}
          role="presentation"
          src={`${CDN}${img}`}
        />
      </div>
    ));
  }

  get style() {
    return {
      left: 1500 - window.innerWidth/2 + 'px',
      background: `url(${CDN}${waterGraphic}) center / cover no-repeat, linear-gradient(to bottom, #294654, #000000)`
    };
  }

  render() {
    return (
      <div className="home-content">
        {this.backgrounds}
        <div className="parallax">
          {this.parallaxes}
          <div className={"parallax__layer parallax__layer__"+(8)}>
            <img
              ref="waterClear_img"
              role="presentation"
              src={`${CDN}${waterClear}`}
            />
            <div id="parallax__cover" style={this.style}>
              <div id="kk-holder">
                <img
                  id="become"
                  ref='become_img'
                  role="presentation"
                  src={`${CDN}${become}`}
                />
                <img
                  id="fantasy"
                  ref='fantasy_img'
                  role="presentation"
                  src={`${CDN}${fantasy}`}
                />
                <div className="media-row">
                  <img
                    id="koth"
                    ref='koth_img'
                    role="presentation"
                    src={`${CDN}${koth}`}
                  />
                  <iframe
                    width="560"
                    height="315"
                    allowFullScreen
                    src="https://www.youtube.com/embed/44LdLqgOpjo"
                    frameBorder="0"
                  />
                </div>
                <a id="enter-button" href="#">Click Here To Enter</a>
              </div>
              <Content />
              <Lobby />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default KongPage;
