import React from 'react';

import Header from '../components/home/header/Header.jsx';
import Body from '../components/home/Body.jsx';
import Bottom from '../components/home/Bottom.jsx';

// import wood from '../media/background/bar/wood.png';
// import beer from '../media/background/bar/beer.png';
// import sandwich from '../media/background/bar/sandwich.png';
// import dollars from '../media/background/bar/dollars.png';

const wood = 'background/bar/wood.png';
const beer = 'background/bar/beer.png';
const sandwich = 'background/bar/sandwich.png';
const dollars = 'background/bar/dollars.png';

// import rock from '../media/background/cafe/rock.jpg';
// import planner from '../media/background/cafe/planner.png';
// import wrap from '../media/background/cafe/wrap.png';
// import coffee from '../media/background/cafe/coffee.png';

const rock = 'background/cafe/rock.jpg';
const planner = 'background/cafe/planner.png';
const wrap = 'background/cafe/wrap.png';
const coffee = 'background/cafe/coffee.png';

// import velvet from '../media/background/movies/velvet.jpg';
// import soda from '../media/background/movies/soda.png';
// import sourPatch from '../media/background/movies/sourPatch.png';
// import movieTickets from '../media/background/movies/movieTickets.png';

const velvet = 'background/movies/velvet.jpg';
const soda = 'background/movies/soda.png';
const sourPatch = 'background/movies/sourPatch.png';
const movieTickets = 'background/movies/movieTickets.png';

export const SECTIONS = [
  {
    id: 'body-section-1',
    memes: [
      {
        text: 'When you and your friends are trying to make plans in the group chat',
        picture: ['bobsBurgers', 'austinPowers', 'hangover']
      }
    ],
  },
  {
    id: 'body-section-2',
    title: 'Take the Lead',
    background: {
      id: 'bar',
      imageSrc: wood,
      gradient: '',
      absolutePictures: [
        {
          id: 'bar-beer',
          // src: 'beer.png',
          src: beer
        },
        // { id: 'bar-burger',
        //   src: 'burger.png'},
        {
          id: 'bar-sandwich',
          // src: 'sandwich.png',
          src: sandwich,
        },
        {
          id: 'bar-cash',
          // src: 'dollars.png',
          src: dollars,
        },
      ]
    },
    memes: [
      {
        text: 'When the group chat leader lays out the weekend plans',
        picture: 'despicableMe'
      },
      {
        text: 'When you get ignored in the group chat',
        picture: 'tomBrady'
      }
    ],
    paragraphs: [
      `No more waiting for Gru to propose plans because your
      attempts to do so leave you feeling like Tom Brady.`,
      `With Tally, anyone can suggest ideas for what, where, and when to hang out. 
      Just create an outing, pick your options, and invite your friends.`,
      `Each friend gets an invite to the outing, not just another 
      chat message.`
    ]
  },
  {
    id: 'body-section-3',
    title: 'Make Decisions, Not War',
    background: {
      id: 'cafe',
      imageSrc: rock,
      absolutePictures: [
        {
          id: 'cafe-coffee',
          // src: 'coffee.png',
          src: coffee,
        },
        {
          id: 'cafe-planner',
          // src: 'planner.png',
          src: planner,
        },
        {
          id: 'cafe-wrap',
          // src: 'wrap.png',
          src: wrap,
        },
      ],
    },
    memes: [
      {
        text: 'When the group chat is split on what to do this weekend',
        picture: 'civilWar'
      },
      {
        text: 'When you and your friends are trying to decide what to eat',
        picture: 'notebook'
      }
    ],
    paragraphs: [
      `Forget fighting your fellow Avengers or becoming a frustrated
      Ryan Gosling.`,
      `With Tally, outings get created for the place and time with 
      the most votes, cast by you and your friends.`,
      `What's more,  we have venue ratings,  restaurant menus, and movie
      showtimes all in-app,  so you can vote for the best options.`
    ]
  },
  {
    id: 'body-section-4',
    title: 'Stay Up-To-Date',
    background: {
      id: 'movies',
      imageSrc: velvet,
      absolutePictures: [
        // { id: 'movies-popcorn',
        //   src: 'popcornLandscape.png'},
        {
          id: 'movies-soda',
          // src: 'soda.png',
          src: soda,
        },
        {
          id: 'movies-SPK',
          // src: 'sourPatch.png',
          src: sourPatch,
        },
        {
          id: 'movies-tickets',
          // src: 'movieTickets.png',
          src: movieTickets,
        },
      ],
    },
    memes: [
      {
        text: 'How your friends that spam the group chat get motivated.',
        picture: 'wolfWall'
      },
      {
        text: `When you mute the group chat during important planning and fuck
        yourself over.`,
        picture: 'kardashian'
      }
    ],
    paragraphs: [
      `Say goodbye to getting spammed by Leo's stockbrockers or
      buying the wrong costume and ruining the squad's Halloween pic.`,
      `With Tally, each outing comes with its own group chat, giving everyone 
       who's going a designated place to talk about the outing.`,
      `Can't make an outing? No problem. Because Tally has separate 
      chats for each outing, you don't receive any messages or 
      notifications for those you can't attend.`
    ]
  },
];

class Home extends React.Component {

  constructor() {
    super();

    this.state = {
      initialStaticPhone: false,
      fixedPhone: true,
      active: 'body-section-0',
      scrollDir: 'up',
      width: window.innerWidth || document.body.clientWidth
    };

    this.setActive = this.setActive.bind(this);
    this.changeScrollDown = this.changeScrollDown.bind(this);
    this.changeScrollUp = this.changeScrollUp.bind(this);
    this.toggleFixedPhone = this.toggleFixedPhone.bind(this);
    this.handleInitialStaticPhone = this.handleInitialStaticPhone.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({width: window.innerWidth || document.body.clientWidth});
    });
  }

  setActive(active) {
    if (this.state.active !== active) this.setState({active});
  }

  changeScrollDown() {
    this.setState({scrollDir: 'down'});
  }

  changeScrollUp() {
    this.setState({scrollDir: 'up'});
  }

  toggleFixedPhone(mode) {
    if (mode === 'on') this.setState({fixedPhone: true});
    else this.setState({fixedPhone: false});
  }

  handleInitialStaticPhone() {
    this.setState({initialStaticPhone: true});
  }

  render() {
    return (
      <div>
        <Header
          sections={SECTIONS}
          width={this.state.width}
          active={this.state.active}
          direction={this.state.scrollDir}
          fixedPhone={this.state.fixedPhone}
        />
        <Body
          sections={SECTIONS}
          width={this.state.width}
          active={this.state.active}
          setActive={this.setActive}
          direction={this.state.scrollDir}
          setScrollUp={this.changeScrollUp}
          setScrollDown={this.changeScrollDown}
          initialStaticPhone={this.state.initialStaticPhone}
        />
        <Bottom
          width={this.state.width}
          fixedPhone={this.state.fixedPhone}
          handleFixedPhone={this.toggleFixedPhone}
          handleInitialStaticPhone={this.handleInitialStaticPhone}
        />
      </div>
    );
  }
}

export default Home;
