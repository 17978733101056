import React from 'react';

import PhoneColumn from '../phone/PhoneColumn.jsx';

import { getImageUrl } from '../../../utils';

// import appStore from '../../../media/store/black/appStore.png';
// import googlePlayBadge from '../../../media/store/black/googlePlayBadge.png';
// import tallyLogoPurple from '../../../media/tally/tallyLogoPurple.png';

const appStore = 'store/black/appStore.png';
const googlePlayBadge = 'store/black/googlePlayBadge.png';
const tallyLogoPurple = 'tally/tallyLogoPurple.png';

class Header extends React.Component {

  // get background() {
  //   return {
  //     backgroundPosition: 'center center',
  //     backgroundRepeat: 'no-repeat',
  //     backgroundSize: 'cover'
  //   };
  // }

  render() {
    let phone;
    if (this.props.width > 800 && this.props.fixedPhone) {
      phone = (
        <PhoneColumn
          active={this.props.active}
          sections={this.props.sections}
          direction={this.props.direction}
        />
      );
    }
    return (
      <div
        id="header"
        // style={this.background}
      >
        <div className="sections">
          <div className="section-container" style={{width: '100%'}}>
            <section className="section full-window-height">
              {phone}
              <div className="header-text">
                <img
                  id="download-logo"
                  src={getImageUrl(tallyLogoPurple)}
                />
                <h2>Together, made easier.</h2>
                <div id="download-photos">
                  <img
                    className="download-photo"
                    id="ios-download"
                    src={getImageUrl(appStore)}
                  />
                  <img
                    className="download-photo"
                    id="android-download"
                    src={getImageUrl(googlePlayBadge)}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
