import React from 'react';
import { Waypoint } from 'react-waypoint';

import Intro from './Intro.jsx';
import Sections from './sections/Sections.jsx';

let counter = 0;

class Body extends React.Component {
  
  constructor() {
    super();

    this.state = {
      staticPhone: false
    };
    
    this.handleSectionEnter = this.handleSectionEnter.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
    
    this.handleExitEnter = this.handleExitEnter.bind(this);
    this.handleExitLeave = this.handleExitLeave.bind(this);
  }
  
  componentDidUpdate() {
    counter ++;
    if (counter === 2 && this.props.initialStaticPhone) {
      this.setState({staticPhone: true});
    }
  }
  
  get sectionProps() {
    return {
      sections: this.props.sections,
      handleSectionEnter: this.handleSectionEnter,
      direction: this.props.direction,
      setScrollUp: this.props.setScrollUp,
      setScrollDown:this.props.setScrollDown,
      staticPhone: this.state.staticPhone
    };
  }
  
  handleSectionEnter(id) {
    this.props.setActive(id);
  }
  
  handleEnter(e) {
    const id  = 'body-section-0';
    const next = Number(id.charAt(id.length - 1)) + 1;
    if (e.currentPosition === 'inside' && e.previousPosition === 'below') {
      this.props.setScrollUp();
      this.handleSectionEnter(id.slice(0, -1) + next);
    }
  }
  
  handleLeave(e) {
    if (e.currentPosition === 'below' && e.previousPosition === 'inside') {
      this.props.setScrollDown();
      this.handleSectionEnter('body-section-0');
    }
  }
  
  handleExitEnter(e) {
    if (this.props.width < 800) return;
    if (e.currentPosition === 'inside' && e.previousPosition === 'below') {
      document.getElementById('sticky-phone').style.display = "none";
      this.setState({staticPhone: true});
    }
  }
  
  handleExitLeave(e) {
    if (this.props.width < 800) return;
    if (e.currentPosition === 'below' && e.previousPosition === 'inside') {
      if (document.getElementById('sticky-phone')) {
        document.getElementById('sticky-phone').style.display = "block";
      }
      this.setState({staticPhone: false});
    }
  }
  
  render() {
    return (
      <div>
        <Waypoint onEnter={this.handleEnter} onLeave={this.handleLeave} bottomOffset={1}  />
        <Intro width={this.props.width}/>
        <Sections {...this.sectionProps}/>
        <Waypoint onEnter={this.handleExitEnter} onLeave={this.handleExitLeave}/>
      </div>
    );
  }
}

export default Body;
