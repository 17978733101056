import React from 'react';

class Project extends React.Component {
  
  constructor() {
    super();
    
    this.state = {
      hover: false
    };
    
    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
  }
  
  onMouseEnterHandler() {
    this.setState({hover: true});
  }
  
  onMouseLeaveHandler() {
    this.setState({hover: false});
  }
  
  get active() {
    return this.state.hover ? ' active' : '';
  }
  
  render() {
    const {name, alt, image, route, date, git} = this.props.project;
    let gitlink;
    if (git) {
      gitlink = (
        <a href={git} target="_blank">
          <div className="project-link">
            {git.indexOf('hub') > -1 ? <i className="ion-social-github"/> : <i className="devicon-gitlab-plain"></i>}
          </div>
        </a>
      );
    }
    return (
      <div className={"project-container" + this.active} onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler}>
        <a href={route} target="_blank">
        <div className="project">
          <img src={image} alt={alt}/>
          <div className="project-label">
            <h4>{name}</h4>
            <p>{date}</p>
            {gitlink}
          </div>
        </div>
        </a>
      </div>
    );
  }
}

export default Project;