import React from 'react';
// import Gallery from 'react-photo-gallery';
import Gallery from './Gallery.jsx';

class Album extends React.Component {

  getCaption(type, i) {
    switch (type) {
      case 'app-store':
        if (i === 1 || i === 2) {
          return `Emojis used in "Activities" and "Time" cards created by EmojiOne: http://emojione.com/developers/`;
        } else return '';
      case 'instructions':
        if (i < 5)  {
          return `Gesture icons created by Rena Xiao: https://dribbble.com/showwts/2181099-Gesture-Icon-Freebie`;
        } else return '';
      default:
        return '';
    }
  }

  get photos() {
    return this.props.photos.map((photo, i) => (
      {
        src: `https://tamba-website-images.s3.amazonaws.com/${photo}`,
        // src: photo,
        alt: this.props.alts[i],
        width: this.props.width,
        height: this.props.height,
        aspectRatio: this.props.aspectRatio,
        lightboxImage:{
          // src: photo,
          src: `https://tamba-website-images.s3.amazonaws.com/${photo}`,
          caption: this.getCaption(this.props.type, i)
        }
      }
    ));
  }

  get title() {
    switch(this.props.type) {
      case 'app-store':
        return 'app store & play store "screenshots"';
      case 'instructions':
        return 'in-app user instructions';
      case 'screenshots':
        return 'screenshots';
      default:
        break;
    }
  }

  render() {
    return (
      <div id={this.props.type} className="album">
        <h2>{this.title}</h2>
        <p className="descriptor">scroll to view more images</p>
        <div className="gallery-container">
          <Gallery photos={this.photos} />
        </div>
      </div>
    );
  }
}

export default Album;
