import React from 'react';
import _ from 'underscore';

import Phone from './Phone.jsx';

import { getImageUrl } from '../../../utils';

// import inAppScreenshots from '../../../media/tally/inAppScreenshots-13.png';

const inAppScreenshots = 'tally/inAppScreenshots-13.png';

class PhoneColumn extends React.Component {

  constructor() {
    super();
  }

  setWidth() {
    const phone = document.getElementById('sticky-phone');
    const phoneColumn = document.getElementById('phone-column');
    phone.style.width = phoneColumn.offsetWidth + 'px';
  }

  render() {
    const bodySection0 = {
      static: true,
      id: 'body-section-0',
    };

    const activeMeme =
      _.where([bodySection0, ...this.props.sections], {id: this.props.active})[0];

    return (
      <div id="phone-column" className="content-col">
        <img
          id="white-back-phone"
          className="front-bg-card"
          src={getImageUrl(inAppScreenshots)}
        />
        <Phone
          fixed={true}
          activeMeme={activeMeme}
          active={this.props.active}
          direction={this.props.direction}
        />
      </div>
    );
  }
}

export default PhoneColumn;
