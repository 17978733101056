import React from 'react';

import { getImageUrl } from '../utils';

// import tallyLogoWhite from '../media/tally/tallyLogoWhite.png';
const tallyLogoWhite = 'tally/tallyLogoWhite.png';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="footer-container">
          <div className="">
            <div className="footer-logo">
              <a href="/tally-website">
                <img src={getImageUrl(tallyLogoWhite)} />
              </a>
            </div>
            <div className="links">
              {/*<div className="first-column">*/}
                {/*<a href="/about">About</a>*/}
                {/*<a href="mailto:contact@gettally.io">Contact</a>*/}
              {/*</div>*/}
              <div className="second-column">
                <a href="/tally-website/privacy">Privacy</a>
                <a href="/tally-website/terms">Terms</a>
              </div>
            </div>
            {/*<div className="social">*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
