import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
// import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

// import blackIphone from '../../../media/phone/blackIphone.png';

import Screen from './Screen.jsx';

import { getImageUrl } from '../../../utils';

const blackIphone = 'phone/blackIphone.png';

class Phone extends React.Component {

  get dots() {
    if (this.props.active.slice(-1) > 0) {
      return [1,2,3,4].map(dot => (
        <CSSTransition
          key={dot}
          timeout={450}
          classNames="dot-transition"
        >
          <div
            key={dot}
            className={`dot${this.props.active.slice(-1) === String(dot) ? ' active' : ''}`}
          />
        </CSSTransition>
      ));
    }
    return null;
  }

  render() {
    return (
      <div
        id="sticky-phone"
        className={`front-image-columns ${this.props.fixed ? 'sticky-phone-fixed': 'sticky-phone-unfixed'} ${this.props.mobile ? 'mobile-width': ''}`}
      >
        <div id="phone-images">
          <img
            id="iphone"
            alt="blackIphone"
            className="front-bg-phone"
            src={getImageUrl(blackIphone)}
          />
          <div
            id="phone-screen-container"
            // className="front-bg-phone"
          >
            {/*<CSSTransition*/}
            {/*  in={true}*/}
            {/*  timeout={3000}*/}
            {/*  classNames="screen-transition"*/}
            {/*  // transitionName="screen-transition"*/}
            {/*  // transitionEnterTimeout={3000}*/}
            {/*  // transitionLeaveTimeout={3000}*/}
            {/*>*/}
            {/*  /!*{this.state.george ? <div key="uniqueValue">testing this ho</div> : <div key="jggjh">we done tested this ho</div>}*!/*/}
            {/*  <Screen*/}
            {/*    key={this.props.active}*/}
            {/*    active={this.props.active}*/}
            {/*    direction={this.props.direction}*/}
            {/*    memes={this.props.activeMeme.memes}*/}
            {/*    static={this.props.activeMeme.static}*/}
            {/*  />*/}
            {/*</CSSTransition>*/}

            <ReactCSSTransitionReplace
              // in={true}
              // timeout={600}
              // classNames="screen-transition"
              transitionName="screen-transition"
              transitionEnterTimeout={450}
              transitionLeaveTimeout={450}
              // overflowHidden={false}
            >
              <Screen
                key={this.props.active}
                active={this.props.active}
                direction={this.props.direction}
                memes={this.props.activeMeme.memes}
                static={this.props.activeMeme.static}
              />
            </ReactCSSTransitionReplace>
          </div>
        </div>
          <TransitionGroup
            id="dots"
            // in={true}

          >
            {this.dots}
          </TransitionGroup>
      </div>
    );
  }
}

export default Phone;
