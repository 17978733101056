import React from 'react';

import MIT from './../../images/logos/MIT.svg';
// import Google from './../../images/logos/google-logo.png';

const Education = () => {
  return (
    <section id="education" className="resume-section">
      <div className="flex-line">
        <h1>education</h1>
      </div>
      {/*<div className="section-content" >*/}
      {/*  <img id="google" className="section-img" src={Google} alt="google-logo"/>*/}
      {/*  <div className="flex-line bold-500" style={{ justifyContent: "space-around" }}>*/}
      {/*    <h2>Google</h2>*/}
      {/*    <h2>UX Design Certificate</h2>*/}
      {/*    <h2>currently pursuing, 2022</h2>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="section-content" style={{ marginTop: 62 }}>
        <img id="mit" className="section-img" src={MIT} alt="mit-logo"/>
        <div className="flex-line bold-500">
          <h2>massachusetts institute of technology</h2>
          <h2>bachelor of science</h2>
          <h2>chemical engineering</h2>
          <h2>2016</h2>
        </div>
      </div>
    </section>
  );
};

export default Education;
