import React from 'react';

class Tab extends React.Component {

  constructor() {
    super();

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.setCategory(this.props.tab);
  }

  get active() {
    return (this.props.tabState === this.props.tab ? ' active' : '');
  }

  render() {
    return (
      <div onClick={this.handleClick} className={"tab" + this.active}>
        <h2>{this.props.tab === 'photos' ? 'portfolio' : this.props.tab}</h2>
      </div>
    );
  }
}

export default Tab;
