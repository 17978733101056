import React from 'react';

import me from './../images/headshotBowtie1.png';

class Shoulder extends React.Component {
  render() {
    return (
      <div className="shoulder">
        <div className="shoulder-content">
          <img className="me" src={me} alt="myself" />
          <h1>
            <span>tamba</span>
            <span className="bold-500">monrose</span>
          </h1>
          <h2>
            <div>
              <span>design</span>
              <span className="bold-500">engineer</span>
              {/*<span className="bold-500">and</span>*/}

            </div>
            <div id="software-engineer">
              <span>frontend</span>
              {/*<span className="bold-500">end</span>*/}
              <span className="bold-500">engineer</span>
            </div>
            {/*<div>
              <span className="bold-500">front</span>
              <span>end</span>
              <span className="bold-500">developer</span>
            </div>*/}
          </h2>
          <div>
            <p>tamba@mit.edu</p>
            {/*<p>(914) 523-7979</p>*/}
            {/*<p>kansas city, ks</p>*/}
          </div>
          <div className="social-links">
            <a className="" href="https://github.com/tamaccount" target="_blank" rel="noreferrer">
              <i className="devicon-github-plain" />
            </a>
            <a className="" href="https://linkedin.com/in/tamba-monrose/" target="_blank" rel="noreferrer">
              <i className="devicon-linkedin-plain"/>
              {/*<img*/}
              {/*  alt="LinkedIn icon"*/}
              {/*  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linkedin/linkedin-plain.svg"*/}
              {/*/>*/}
            </a>
            <a className="" href="https://websitedevsite.webflow.io" target="_blank" rel="noreferrer">
              <ion-icon name="briefcase" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Shoulder;
