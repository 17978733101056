import React from 'react';
import Lightbox, { Modal, ModalGateway } from 'react-images';

const style = {
  display: 'block',
  margin: 2,
  float: 'left'
};

class Gallery extends React.Component {

  constructor() {
  	super();
  	this.state = {
	    currentImage: 0,
	    containerWidth: 0,
      lightboxIsOpen: false,
    };

  	this.handleResize = this.handleResize.bind(this);
  	this.closeLightbox = this.closeLightbox.bind(this);
  	this.gotoNext = this.gotoNext.bind(this);
  	this.gotoPrevious = this.gotoPrevious.bind(this);
  	this.openLightbox = this.openLightbox.bind(this);
  }

  componentDidMount(){
    this.setState({containerWidth: Math.floor(this._gallery.clientWidth)});
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(){
    if (this._gallery.clientWidth !== this.state.containerWidth){
      this.setState({containerWidth: Math.floor(this._gallery.clientWidth)});
    }
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize, false);
  }

  handleResize(e){
    this.setState({containerWidth: Math.floor(this._gallery.clientWidth)});
  }

  openLightbox(index, event){
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true
    });
  }

  closeLightbox(){
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious() {
    this.setState({currentImage: this.state.currentImage - 1});
  }

  gotoNext() {
    this.setState({currentImage: this.state.currentImage + 1});
  }

  render(){
    var rowLimit = 1,
      photoPreviewNodes = [];

    if (this.state.containerWidth >= 480){
      rowLimit = 2;}
    if (this.state.containerWidth >= 1024){
      rowLimit = 3;}

    var contWidth = this.state.containerWidth - (rowLimit * 4); /* 4px for margin around each image*/
    contWidth = Math.floor(contWidth - 2); // add some padding to prevent layout prob

    var remainder = this.props.photos.length % rowLimit;
    if (remainder) { // there are fewer than rowLimit photos in last row
      var lastRowWidth = Math.floor(this.state.containerWidth - (remainder * 4) - 2);
      var lastRowIndex = this.props.photos.length - remainder;
    }

	  var lightboxImages = [];
    for (var i = 0; i < this.props.photos.length; i += rowLimit) {
      // loop thru each set of rowLimit num
      // eg. if rowLimit is 3 it will  loop thru 0,1,2, then 3,4,5 to perform calculations for the particular set
      var totalAr = 0,
        commonHeight = 0;

      for (var j = i; j < i+rowLimit; j++) {
        if (j === this.props.photos.length) {
          break;}
        totalAr += this.props.photos[j].aspectRatio;}

      if (i === lastRowIndex) {
        commonHeight = lastRowWidth / totalAr;}
      else {
        commonHeight = contWidth / totalAr;}

      // run thru the same set of items again to give the common height
      for (var k = i; k < i + rowLimit; k++) {
        if (k === this.props.photos.length) {
          break;}

		  var src = this.props.photos[k].src;
		  var alt = this.props.photos[k].alt;

      if (this.props.disableLightbox) {
        photoPreviewNodes.push(
          <div key={k} style={style}>
            <img src={src} style={{display:'block', border:0}}
              width={commonHeight * this.props.photos[k].aspectRatio}
              height={commonHeight} alt={alt} />
          </div>
        )}
		  else {
        lightboxImages.push(this.props.photos[k].lightboxImage);
        photoPreviewNodes.push(
          <div key={k} style={style}>
            <a href="#" className={k} onClick={this.openLightbox.bind(this, k)}>
              <img
                src={src}
                style={{display:'block', border:0}}
                width={commonHeight * this.props.photos[k].aspectRatio}
                height={commonHeight}
                alt={alt}
              />
            </a>
          </div>
        )}
      }
    }
	  return(this.renderGallery(photoPreviewNodes, lightboxImages));
  }

  renderGallery(photoPreviewNodes, lightboxImages){
    if (this.props.disableLightbox){
    return (
      <div className="gallery clearfix" ref={(c) => this._gallery = c}>
        {photoPreviewNodes}
      </div>
      );
    }
  else {
    return (
      <div className="gallery clearfix" ref={(c) => this._gallery = c}>
        {photoPreviewNodes}
        <ModalGateway>
          {this.state.lightboxIsOpen ? (
            <Modal onClose={this.closeLightbox}>
              <Lightbox
                views={lightboxImages}
                currentIndex={this.state.currentImage}
                onClickPrev={this.gotoPrevious}
                onClickNext={this.gotoNext}
                width={1600}
                // showImageCount={this.props.lightboxShowImageCount}
                // backdropClosesModal={this.props.backdropClosesModal}
                // preloadNextImage={this.props.preloadNextImage}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    )}
  }
}
export default Gallery;
