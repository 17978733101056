import React from 'react';
import ScrollAnim from 'rc-scroll-anim';

import Phone from './phone/Phone.jsx';

import { getImageUrl } from '../../utils';

// import beatsPill from '../../media/background/park/beatsPill.png';
// import flippy from '../../media/background/park/flippy.jpg';
// import sunglasses from '../../media/background/park/sunglasses.png';
// import sandBright from '../../media/background/park/sandBright.jpg';

const beatsPill = 'background/park/beatsPill.png';
const flippy = 'background/park/flippy.jpg';
const sunglasses = 'background/park/sunglasses.png';
const sandBright = 'background/park/sandBright.jpg';

const ScrollParallax = ScrollAnim.Parallax;

class Intro extends React.Component {

  get absolutePictures() {
    return [
      {
        id: 'park-speaker',
        // src: 'beatsPill.png',
        src: beatsPill,
      },
      {
        id: 'park-flops',
        // src: 'flippy.jpg',
        src: flippy,
      },
      // { id: 'park-frisbee',
      //   src: 'frisbee.png'},
      {
        id: 'park-sunglasses',
        // src: 'sunglasses.png',
        src: sunglasses,
      },
    ];
  }

  get backgroundImages() {
    return this.absolutePictures.map((img, i) => (
      <img
        key={i}
        id={img.id}
        src={getImageUrl(img.src)}
        className="absolute-picture"
      />
    ));
  }

  render() {
    let mobileWidthPhone;
    const memes = [{
      text: 'When you and your friends are trying to make plans in the group chat',
      picture: ['bobsBurgers', 'austinPowers', 'hangover']
    }];

    if (this.props.width <= 800) {
      mobileWidthPhone = (
        <Phone
          active='body-section-1'
          diretion='down'
          fixed={false}
          mobile={true}
          activeMeme={{memes}}
        />
      );
    }
    return (
      <div className="intro" id="body-section-1">
        <div className="section-container full-window-height"
          style={{
            // backgroundImage: `url(${sandBright})`,
            backgroundImage: `url(${getImageUrl(sandBright)})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}>
          <div className="parallax-layer">
            <ScrollParallax
              style={{ transform: 'translateY(80px)', height: '100%'}}
              animation={{ translateY: -80, ease: 'linear', playScale: [0, 1] }}
            >
              {this.backgroundImages}
            </ScrollParallax>
          </div>
          <div className="section">
            <div className="intro-imgs section-imgs">
              {mobileWidthPhone}
            </div>
            <div className="section-text">
              <h3>We Know What You Meme</h3>
              <p>We've seen the memes and we get it.</p>
              <p>Planning hangouts is difficult.</p>
              <p>
                <i>
                  When is best?<br/>
                  Who wants to do what? <br/>
                  Where does everyone want to go?
                </i><br/>
              </p>
              <p>
                Reaching a consensus amongst a few friends
                is hard enough. Trying to do so in a group chat while
                everyone is roasting each other, spamming dank memes,
                and sharing the latest gifs only makes things harder.
              </p>
              <p>That's why we created Tally:</p>
              <p>To eliminate the hassle of getting together.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;
