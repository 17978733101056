import React from 'react';
import Link from './Link.jsx';

class Links extends React.Component {

  get links() {
    return this.props.links.map((link, i) => (
      <Link key={i} id={this.props.id} link={link} />
    ));
  }

  render() {
    return (
      <div id="links" className="exp-section">
        <h2>{this.props.linkMessage}</h2>
        {this.links}
      </div>
    );
  }
}

export default Links;
