import React from 'react';

import Section from './Section.jsx';

class Sections extends React.Component {

  constructor() {
    super();
  }

  renderSections() {
    const sections = this.props.sections.slice(1, 4);
    return sections.map((section, i) => (
      <Section
        key={i}
        section={section}
        handleSectionEnter={this.props.handleSectionEnter}
        setScrollUp={this.props.setScrollUp}
        setScrollDown={this.props.setScrollDown}
        direction={this.props.direction}
        last={i === sections.length - 1}
        staticPhone={this.props.staticPhone}
      />
    ));
  }

  render() {
    return (
      <div className="middle">
        <div className="sections-container">
          <div className="sections">
            {this.renderSections()}
          </div>
        </div>
      </div>
    );
  }
}

export default Sections;
