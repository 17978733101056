import React from 'react';
import moment from 'moment';

class TableCard extends React.Component {

  renderIcon(browser) {
    switch(browser) {
      case 'Chrome':
        return <img alt="chrome" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/chrome/chrome-original.svg"/>;
      case 'Internet Explorer':
        return <img alt="ir" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/ie10/ie10-original.svg"/>;
      case 'Firefox':
        return <img alt="firefox" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firefox/firefox-original.svg"/>;
      case 'Safari':
        return <img alt="safari" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/safari/safari-original.svg"/>;
      default:
        break;
    }
  }

  get rows() {
    const {format} = this.props;
    if (format === "users") {
      return this.props.data.map((point, i) => {
        const dateAgo = moment(new Date(point["Log In Date"])).fromNow();
        return (
          <tr key={i}>
            <td>{point.user}</td>
            <td>{dateAgo.replace('hour', 'hr').replace('minute', 'min').replace('second', 'sec')}</td>
            <td>{this.renderIcon(point.browser)}</td>
          </tr>
        );
      });
    }
    if (format === "terms") {
      return this.props.data.map((point, i) => {
        return (
          <tr key={i}>
            <td>
              <div>
                {point.label}
              </div>
            </td>
            <td style={{width:"105px"}}>
              <div>
                {point.num}
              </div>
            </td>
			      <td style={{width:"85px"}}>{point.positive ? <i className="ion-checkmark-round"/> : <i className="ion-close-round"/>  }</td>
          </tr>
        );
      });
    } else {
      const {data} = this.props;
      const keys = Object.keys(data);
      return keys.sort((a, b) => data[b] - data[a]).map((row, i) => (
        <tr key={i}>
          <td>
            <div>
              {keys[i].charAt(0).toUpperCase() + keys[i].slice(1)}
            </div>
          </td>
          <td>
            <div>
              {data[keys[i]]}
            </div>
          </td>
        </tr>
      ));
    }
  }

  get table() {
    return (
      <table>
        <thead>
          <tr>
            {this.props.head.map((cell, i) => <th key={i}>{cell}</th>)}
          </tr>
        </thead>
        <tbody>
          {this.rows}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <div className="large-card card header-card table-card">
        <div className="card-header">{this.props.title}</div>
        <div className="card-content">
          {this.table}
        </div>
      </div>
    );
  }
}

export default TableCard;

